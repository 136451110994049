import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import { useClassByIdQuery, useStripeSecret } from "old/src/hooks/useParent";
import { GET_STRIPE } from "src/constants/client";
import CCPayment from "src/pageComponents/AdditionalSingleSession/Booking/CCPayment";
import FreeTrial from "src/pageComponents/AdditionalSingleSession/Booking/FreeTrial";
import { logTutorEvent } from "src/utils/log";
import React from "react";
import { useGlobalStore } from "old/src/store";
import useSessionIsApplicableForFreeTrial from "src/hooks/useSessionIsApplicableForFreeTrial";
import { isBefore, addHours, parse, formatISO } from "src/utils/dates";
import { useSubscriptionAvailabilityQuery } from "#/old/src/hooks/useTeacher";

const MINIMAL_AVAILABLE_SLOTS = 11;

const useIsEligibleForFreeTrial = () => {
  const [state] = useGlobalStore();
  const sessionToBeReplacedId = state.booking?.replacementForSession;
  const { subscriptionAvailability, isLoading: isSubscriptionAvailabilityLoading } = useSubscriptionAvailabilityQuery(
    state.booking?.teacher_uid,
  );
  const { data: isApplicableForFreeTrialData, isFetching: isApplicableForFreeTrialLoading } =
    useSessionIsApplicableForFreeTrial(sessionToBeReplacedId);
  const tutorSufficientlyAvailable =
    Object.keys(subscriptionAvailability ?? {}).flatMap((day) => subscriptionAvailability?.[day] ?? []).length >=
    MINIMAL_AVAILABLE_SLOTS;

  const isLoading =
    isSubscriptionAvailabilityLoading ||
    isApplicableForFreeTrialLoading ||
    (!!sessionToBeReplacedId && !isApplicableForFreeTrialData?.data);

  const isEligibleForFreeTrial =
    sessionToBeReplacedId && (!tutorSufficientlyAvailable || isApplicableForFreeTrialData?.data.isApplicable);

  if (sessionToBeReplacedId && !isLoading) {
    logTutorEvent({
      eventName: "parent.marketplace.additional_single_class.booking.free_trial_check",
      metadata: {
        tutorSufficientlyAvailable,
        isApplicableForFreeTrial: isApplicableForFreeTrialData?.data.isApplicable,
        sawFreeTrialForm: isEligibleForFreeTrial,
        sessionToBeReplacedId,
      },
    });
  }

  return {
    isEligibleForFreeTrial,
    isLoading,
  };
};

export default () => {
  const push = useNavigate();
  const { classId } = useParams();
  const { classData, isInitialLoading: isClassDataLoading } = useClassByIdQuery(classId);
  const { clientSecret, isFetching, isSuccess } = useStripeSecret({ id: classId });
  const { isEligibleForFreeTrial, isLoading: isFreeTrialCheckingLoading } = useIsEligibleForFreeTrial();

  React.useEffect(() => {
    if (classData) {
      const sessionTime = parse(classData.startTime);
      if (classData.status === "paid") {
        push("/parents/classes");
      } else if (isBefore(sessionTime, addHours(new Date(), 24))) {
        push("/additional-single-class/step-six");
      } else {
        logTutorEvent({
          eventName: "parent.marketplace.logged_in_trial_booking.step_4",
          metadata: {
            subject: classData.subject,
            datetime: sessionTime.toISOString(),
            date: formatISO(sessionTime, { representation: "date" }),
            time: formatISO(new Date(), { representation: "time", format: "extended" }).substring(0, 5),
          },
        });
      }
    }
  }, [classData, push]);

  if (!clientSecret || isFetching || isFreeTrialCheckingLoading || !classData || isClassDataLoading || !isSuccess) {
    return null;
  }

  if (isEligibleForFreeTrial) {
    return <FreeTrial classData={classData} />;
  }

  return (
    <Elements stripe={GET_STRIPE()} options={{ clientSecret: clientSecret?.client_secret }}>
      <CCPayment clientSecret={clientSecret?.client_secret} classData={classData} />
    </Elements>
  );
};
