import { Button } from "@classdojo/web/nessie";
import { GlobalCSS, RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import { useModalStore } from "./index";
import useHideHelperWidget from "src/hooks/useHideHelperWidget";
import { IconX } from "@web-monorepo/dds-icons";
import { DDSLabelText } from "@web-monorepo/dds";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

type ModalWrapperProps = {
  children: ReactNode;
  hideCloseButton?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  variant: "fullscreen" | "centered" | "small" | "bottomDrawer";
  maxWidth?: string;
};

const ModalWrapper = ({ children, hideCloseButton = false, onClose, title, variant, maxWidth }: ModalWrapperProps) => {
  const hideModal = useModalStore((state) => state.hideModal);

  // fullscreen modal should close on back button
  useEffect(() => {
    if (variant === "fullscreen") {
      window.history.pushState(null, "", window.location.pathname);
      const handlePopState = () => {
        hideModal();
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [hideModal, variant]);

  useHideHelperWidget();

  const sxOverlayStyle = {
    centered: sxOverlayCentered,
    small: sxOverlaySmall,
    fullscreen: sxOverlayFullscreen,
    bottomDrawer: sxOverlayBottomDrawer,
  };

  const sxWrapperStyle = {
    centered: sxWrapperCentered,
    small: sxWrapperSmall,
    fullscreen: sxWrapperFullscreen,
    bottomDrawer: sxWrapperBottomDrawer,
  };

  const sxCloseButtonStyle = {
    centered: sxTransparentCloseButton,
    small: sxTransparentCloseButton,
    fullscreen: sxTransparentCloseButton,
    bottomDrawer: sxTransparentCloseButton,
  };

  return ReactDOM.createPortal(
    <>
      <GlobalCSS
        styles={{
          html: {
            overflow: "hidden",
          },
        }}
      />
      <Dialog.Root modal open>
        <Dialog.Overlay sx={sxOverlayStyle[variant]} />
        <Dialog.Content sx={{ ...sxWrapperStyle[variant], maxWidth }}>
          {!hideCloseButton && (
            <div sx={sxSplitCloseButtonWrapper}>
              <Button
                type="button"
                sx={sxCloseButtonStyle[variant]}
                onClick={onClose ?? hideModal}
                kind="secondary"
                size="s"
                data-name="modal_wrapper:close"
              >
                <IconX size="m" />
              </Button>
            </div>
          )}

          {title && (
            <>
              <VisuallyHidden asChild>
                <Dialog.Title>{title}</Dialog.Title>
              </VisuallyHidden>
              <div sx={sxTitle}>
                <DDSLabelText level={1}>{title}</DDSLabelText>
              </div>
            </>
          )}
          <div sx={sxContent}>{children}</div>
        </Dialog.Content>
      </Dialog.Root>
    </>,
    document.body,
  );
};

export default ModalWrapper;

const sxOverlay: ThemeUIStyleObject = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 999,
};

const sxOverlayCentered: ThemeUIStyleObject = {
  ...sxOverlay,
  backgroundColor: RAW_cssValue("rgba(0, 0, 0, 0.5)"),
  backdropFilter: "blur(10px)",
};

const sxOverlaySmall: ThemeUIStyleObject = {
  ...sxOverlayCentered,
};

const sxOverlayFullscreen: ThemeUIStyleObject = { ...sxOverlay };

const sxOverlayBottomDrawer: ThemeUIStyleObject = {
  ...sxOverlay,
  backgroundColor: RAW_cssValue("rgba(0, 0, 0, 0.5)"),
  backdropFilter: "blur(10px)",
};

const sxWrapper: ThemeUIStyleObject = {
  position: "fixed",
  backgroundColor: "dt_background_primary",
  zIndex: 1000,
  opacity: 1,
  transition: "opacity 0.3s ease-out",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

const sxWrapperCentered: ThemeUIStyleObject = {
  ...sxWrapper,
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 624,
  borderRadius: "dt_radius_l",
  maxWidth: "calc(100% - 24px)",
  maxHeight: "calc(100% - 24px)",
};

const sxWrapperSmall: ThemeUIStyleObject = {
  ...sxWrapperCentered,
  width: 351,
};

const sxWrapperFullscreen: ThemeUIStyleObject = {
  ...sxWrapper,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const sxWrapperBottomDrawer: ThemeUIStyleObject = {
  ...sxWrapper,
  left: 0,
  right: 0,
  bottom: 0,
  borderTopLeftRadius: "24px",
  borderTopRightRadius: "24px",
};

const sxContent: ThemeUIStyleObject = {
  paddingBottom: "dt_l",
  paddingRight: "dt_l",
  paddingLeft: "dt_l",
  flexGrow: 1,
};

const sxTitle: ThemeUIStyleObject = {
  paddingBottom: "dt_s",
  paddingRight: "dt_l",
  paddingLeft: "dt_l",
  flexGrow: 1,
};

const sxCloseButton: ThemeUIStyleObject = {
  gridColumn: 3,
};

const sxTransparentCloseButton: ThemeUIStyleObject = {
  ...sxCloseButton,
  backgroundColor: "transparent",
};

const sxSplitCloseButtonWrapper: ThemeUIStyleObject = {
  width: "100%",
  display: "flex",
  justifyContent: "right",
  padding: "18px",
  paddingBottom: "0",
};
