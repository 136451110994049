import useCallWhenReady from "#/src/hooks/useCallWhenReady";
import * as logClient from "@classdojo/log-client";
import { logConvertExperiment, logStartExperiment, setEntityType } from "@classdojo/log-client";
import { useCallback, useEffect } from "react";
import { LOGGED_IN_SWITCH_MAP, LOGGED_OUT_SWITCH_MAP } from "src/constants/featureSwitches";
import { useSessionQuery } from "src/hooks/useSession";
import { setFeatureSwitchesConfig } from "@web-monorepo/shared/featureSwitches";
import { DevToolsSettings } from "src/utils/devTools";
import { useFetchedFeatureSwitch } from "@web-monorepo/shared/featureSwitches";
import { useNavigate, type To, type NavigateOptions } from "react-router-dom";
import { useOnFirstRender } from "@web-monorepo/hooks";

const getRole = (roles: string[]) => (roles.includes("teacher") ? "tutor" : "parent");

export const LOGGED_OUT_SWITCH_NAMES = Object.keys(LOGGED_OUT_SWITCH_MAP) as Array<keyof typeof LOGGED_OUT_SWITCH_MAP>;
export const LOGGED_IN_SWITCH_NAMES = Object.keys(LOGGED_IN_SWITCH_MAP) as Array<keyof typeof LOGGED_IN_SWITCH_MAP>;
const FEATURE_SWITCH_VALUES = { ...LOGGED_OUT_SWITCH_MAP, ...LOGGED_IN_SWITCH_MAP } as const;

export type FeatureName = (typeof LOGGED_OUT_SWITCH_NAMES)[number] | (typeof LOGGED_IN_SWITCH_NAMES)[number];
type LoggedOutFeatureSwitches = {
  [key in (typeof LOGGED_OUT_SWITCH_NAMES)[number]]: (typeof LOGGED_OUT_SWITCH_MAP)[key]["values"][number];
};
type LoggedInFeatureSwitches = {
  [key in (typeof LOGGED_IN_SWITCH_NAMES)[number]]: (typeof LOGGED_IN_SWITCH_MAP)[key]["values"][number];
};
type FeatureSwitches = LoggedOutFeatureSwitches & LoggedInFeatureSwitches;

interface FeatureSwitchOptions {
  isInAudience?: boolean;
}

const useFeatureSwitch = <TSwitchName extends FeatureName>(
  switchName: TSwitchName,
  { isInAudience = true }: FeatureSwitchOptions = {},
): {
  data: FeatureSwitches[TSwitchName] | undefined;
  convertExperiment: () => void;
  startExperiment: () => void;
  isInExperiment: boolean;
} => {
  const featureSwitch = useFetchedFeatureSwitch(switchName) as FeatureSwitches[TSwitchName];
  const callWhenReady = useCallWhenReady(!!featureSwitch);

  const startExperiment = useCallback(() => {
    callWhenReady(() => {
      const switchMetadata = FEATURE_SWITCH_VALUES[switchName] || {};
      if ("entityType" in switchMetadata) {
        setEntityType(switchMetadata.entityType);
      }

      logStartExperiment(switchName);
    });
  }, [callWhenReady, switchName]);

  const convertExperiment = useCallback(
    () => callWhenReady(() => logConvertExperiment(switchName)),
    [callWhenReady, switchName],
  );

  return isInAudience
    ? {
        data: featureSwitch,
        convertExperiment,
        startExperiment,
        isInExperiment: featureSwitch != null && featureSwitch !== "off" && featureSwitch !== "control",
      }
    : {
        data: "off" as FeatureSwitches[TSwitchName],
        convertExperiment: () => {},
        startExperiment: () => {},
        isInExperiment: false,
      };
};

export const useFeatureSwitchAndStartExperiment = <TSwitchName extends FeatureName>(
  rawSwitchName: TSwitchName,
  { isInAudience = true }: FeatureSwitchOptions = {},
) => {
  const { startExperiment, ...switchObject } = useFeatureSwitch(rawSwitchName, { isInAudience });

  useEffect(() => {
    if (!isInAudience) return;
    if (switchObject.data && switchObject.data !== "off") {
      startExperiment();
    }
  }, [rawSwitchName, isInAudience, startExperiment, switchObject.data]);

  return switchObject;
};

export const useConvertExperimentOnLoad = (switchName: FeatureName, options?: FeatureSwitchOptions) => {
  const { convertExperiment } = useManualFeatureSwitch(switchName, { ...options });

  useOnFirstRender(() => {
    convertExperiment();
  });
};

export const useManualFeatureSwitch = <TSwitchName extends FeatureName>(
  switchName: TSwitchName,
  options: FeatureSwitchOptions = {},
) => {
  const featureSwitchResponse = useFeatureSwitch(switchName, { ...options });

  const switchMetadata = FEATURE_SWITCH_VALUES[switchName];

  if ("entityType" in switchMetadata) {
    setEntityType(switchMetadata.entityType);
  }

  const startExperiment = useCallback(() => logStartExperiment(switchName), [switchName]);

  return {
    ...featureSwitchResponse,
    startExperiment,
  };
};

const useBuildFeatureSwitchesQueryParamsHook = () => {
  const { isLoading, isFetching, sessionData } = useSessionQuery();
  if (isLoading || isFetching) return null;
  if (sessionData?.roles) {
    const role = getRole(sessionData.roles);
    return {
      featureSetId: "1",
      [`${role}Id`]: sessionData?.user_details.uid as string,
      [`${role}CountryCode`]: "US" as string,
      [`${role}Switches`]: LOGGED_IN_SWITCH_NAMES.join(",") as string,
      sessionId: logClient.getSessionId(),
      sessionSwitches: LOGGED_OUT_SWITCH_NAMES.join(",") as string,
    };
  }

  // If the user is not logged in, we need to send the session ID and the list of feature switches.
  return {
    featureSetId: "1",
    sessionId: logClient.getSessionId(),
    sessionSwitches: LOGGED_OUT_SWITCH_NAMES.join(",") as string,
  };
};

export function setAppFeatureSwitchesConfig() {
  setFeatureSwitchesConfig({
    featureSetIdLocalStorageKey: `marketplace/featureSwitches/featureSetId`,
    useBuildFeatureSwitchesQueryParamsHook,
    useFeatureSwitchOverridesHook: () => {
      const { featureSwitchOverrides } = DevToolsSettings.useFeatureSwitchOverrides();
      return featureSwitchOverrides;
    },
    featureSwitchesFetcherQueryParams: [
      "sessionId",
      "sessionSwitches",
      "parentId",
      "parentSwitches",
      "tutorId",
      "tutorSwitches",
      "parentCountryCode",
      "tutorCountryCode",
    ],
  });
}

// we dont unmount all components when logging in & refetching switches
// but before visiting a logged in page, we want to make sure switches are there
export const useNavigateWhenLoggedInSwitchesLoaded = () => {
  const loggedInSwitch = Object.keys(LOGGED_IN_SWITCH_MAP)[0];
  const featureSwitch = useFetchedFeatureSwitch(loggedInSwitch);
  const callWhenReady = useCallWhenReady(!!featureSwitch);
  const push = useNavigate();

  return (url: To, options?: NavigateOptions) => callWhenReady(() => push(url, options));
};
