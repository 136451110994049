import throttle from "lodash/throttle";
import { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import TeacherAPI from "src/api/teacher";
import useMutation from "src/hooks/useMutation";
import { useSessionQuery } from "src/hooks/useSession";
import { handleError } from "src/queryClient";
import { addYears } from "src/utils/dates";

const useOnboard = () => {
  return useMutation(TeacherAPI.onboardTeacher);
};

const useUpdateAvailability = () => {
  return useMutation(TeacherAPI.updateAvailability);
};

const useDeleteDaysOff = () => {
  return useMutation(TeacherAPI.deleteDaysOff);
};

const useUpdateDaysOff = () => {
  return useMutation(TeacherAPI.updateDaysOff);
};

const useUpdateTeacherProfile = () => {
  return useMutation(TeacherAPI.updateTeacherProfile);
};

export const useAvailabilityQuery = () => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["availability"], () => TeacherAPI.getAvailability(), {
    enabled: sessionData !== null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: handleError(true),
  });

  return {
    ...query,
    availability: query.data?.data?.availability,
  };
};

export const useDaysOffQuery = () => {
  const { sessionData } = useSessionQuery();
  const q = {
    start_ts: new Date().toISOString(),
    end_ts: addYears(new Date(), 1).toISOString(),
  };

  const query = useQuery(["daysOff"], () => TeacherAPI.getDaysOff(q), {
    enabled: sessionData !== null,
    keepPreviousData: true,
  });

  return {
    ...query,
    daysOff: query.data?.data,
  };
};

export const usePrivateTeacherProfileQuery = () => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["teacherProfile"], () => TeacherAPI.getTeacherPrivateProfile(), {
    enabled: sessionData !== null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    teacher: query.data?.data,
  };
};

export const useTeacherSessionScheduleQuery = () => {
  const { sessionData } = useSessionQuery();
  const [q, setQ] = useState<Parameters<typeof TeacherAPI.getSessionSchedule>[0]>();

  const query = useQuery(
    ["teacherSessionSchedule", q],
    () => {
      if (q != null) {
        return TeacherAPI.getSessionSchedule(q);
      }

      return null;
    },
    {
      enabled: sessionData !== null,
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
    },
  );

  const { current: search } = useRef(
    throttle(async (object) => {
      if (object) {
        setQ((prevQ) => ({ ...prevQ, ...object }));
      }
    }, 500),
  );

  const schedule = query.data?.data;
  const filteredSchedule = schedule
    ? schedule.filter(
        (session) =>
          !(session.scheduleStatus === "rescheduled" && session.canceledOrRescheduledReason === "substituteTutor"),
      )
    : undefined;

  return {
    ...query,
    search,
    schedule: filteredSchedule,
  };
};

export const useSubscriptionAvailabilityQuery = (id?: string | null) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["subscriptionAvailability", id], () => TeacherAPI.getTeacherSubscriptionAvailability(id!), {
    enabled: id != null && sessionData !== null,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    subscriptionAvailability: query.data?.data,
  };
};

export const useGeneratedAssessmentQuery = (classId?: string) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["generatedAssessment", classId], () => TeacherAPI.getGeneratedAssessment(classId!), {
    enabled: classId != null && sessionData !== null,
    refetchOnWindowFocus: false,
    onError: () => {},
  });

  return {
    ...query,
    generatedAssessment: query.data?.data,
  };
};

const useCreateAssessment = () => {
  return useMutation(TeacherAPI.createAssessment);
};

export const useTutorSubscriptionConflictsQuery = (
  body?: Parameters<typeof TeacherAPI.getSubscriptionConflicts>[0],
) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["subscriptionConflicts", body], () => TeacherAPI.getSubscriptionConflicts(body!), {
    enabled: sessionData !== null && body != null,
    refetchOnWindowFocus: false,
  });

  return {
    conflicts: query.data?.data,
    isLoading: query.isLoading,
  };
};

export const useTutorMeetingQuery = (token: string | undefined) => {
  const query = useQuery(["tutorMeeting", token], () => TeacherAPI.getTutorMeeting(token!), {
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    zoomMeetingURL: query.data?.data.zoomTutorURL,
  };
};

export const useTeacherMutation = () => {
  const onboard = useOnboard();
  const updateAvailability = useUpdateAvailability();
  const updateTeacherProfile = useUpdateTeacherProfile();
  const updateDaysOff = useUpdateDaysOff();
  const deleteDaysOff = useDeleteDaysOff();
  const createAssessment = useCreateAssessment();

  return {
    onboard,
    updateAvailability,
    updateTeacherProfile,
    updateDaysOff,
    deleteDaysOff,
    createAssessment,
  };
};

export const useClassAssessmentByIdQuery = (id?: string) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["assessment", id, sessionData], () => TeacherAPI.getClassById(id!), {
    enabled: id != null && sessionData != null,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return {
    ...query,
    classData: query.data?.data,
  };
};

export const useGeneratedAssessmentReviewMutation = () => {
  return useMutation(TeacherAPI.createGeneratedAssessmentReview);
};
