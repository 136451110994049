import CreatePassword from "old/src/pageComponents/CreatePassword";
import Meeting from "old/src/pageComponents/Meeting";
import Invoices from "old/src/pageComponents/Parents/Invoices";
import ChildPrivacyTerms from "old/src/pageComponents/Privacy/ChildPrivacyTerms";
import PrivacyPolicyJanuary2023 from "old/src/pageComponents/Privacy/PrivacyPolicyJanuary2023";
import Availability from "old/src/pageComponents/Teachers/Availability";
import ThirdPartyServiceProviders from "old/src/pageComponents/ThirdPartyServiceProviders";
import Transparency from "old/src/pageComponents/Transparency";
import TutorMeeting from "old/src/pageComponents/TutorMeeting";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import RequireLoggedIn from "./components/RequireLoggedIn";
import AITransparencyNote from "./pageComponents/AITransparencyNote";
import AdditionalTrialSessionBooking from "./pageComponents/AdditionalSingleSession/Booking";
import AdditionalSingleSessionSuccess from "./pageComponents/AdditionalSingleSession/Success";
import TutorCreateAssessment from "./pageComponents/Assessment/Create";
import GeneratedAssessmentReview from "./pageComponents/Assessment/GeneratedAssessmentReview";
import ParentAssessment from "./pageComponents/Assessment/ParentView";
import { sxWrapperBackground } from "./pageComponents/NewSingleClass/SharedStyles";
import NotFound from "./pageComponents/NotFound";
import ConsentConfirmation from "./pageComponents/Parent/ConsentConfirmation";
import PostSessionSurvey from "./pageComponents/PostSessionSurvey";
import SubscriptionBookingRedirectFromClass from "./pageComponents/SubscriptionBookingRedirectFromClass";
import useIsMobile from "./hooks/useIsMobile";

const AppRouter = () => {
  const isMobile = useIsMobile();
  return (
    <Routes>
      <Route
        path="/create-password/:token/:email"
        element={
          <Layout layout="centered">
            <CreatePassword />
          </Layout>
        }
      />
      <Route
        path="/meeting/:token"
        element={
          <Layout layout="centered">
            <Meeting />
          </Layout>
        }
      />
      <Route
        path="/tutor/meeting/:token"
        element={
          <Layout layout="centered">
            <TutorMeeting />
          </Layout>
        }
      />
      <Route
        path="/parents/classes/assessment/:assessmentId"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <ParentAssessment />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/invoices"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <Invoices />
            </Layout>
          </RequireLoggedIn>
        }
      />

      <Route
        path="/parents/review"
        element={
          <RequireLoggedIn>
            <Layout layout="default">
              <PostSessionSurvey />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/share-assessment/:stripeSubscription"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <ConsentConfirmation />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/child-privacy-terms"
        element={
          <Layout layout="padded">
            <ChildPrivacyTerms />
          </Layout>
        }
      />

      <Route
        path="/privacy-jan-2023"
        element={
          <Layout layout="padded">
            <PrivacyPolicyJanuary2023 />
          </Layout>
        }
      />
      <Route
        path="/teachers/availability"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <Availability />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/teachers/calendar/assessment/:assessmentId"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <TutorCreateAssessment />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/teachers/generatedAssessment/:generatedAssessmentId/review"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <GeneratedAssessmentReview />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/third-party-service-providers"
        element={
          <Layout layout="padded">
            <ThirdPartyServiceProviders />
          </Layout>
        }
      />
      <Route
        path="/transparency"
        element={
          <Layout layout="padded">
            <Transparency />
          </Layout>
        }
      />
      <Route
        path="/subscription/booking/class/:classId/redirect-from-class"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <SubscriptionBookingRedirectFromClass />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/booking/:classId"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground} header={!isMobile}>
              <AdditionalTrialSessionBooking />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/booking/:classId/success"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <AdditionalSingleSessionSuccess />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/ai-transparency-note"
        element={
          <Layout layout="padded">
            <AITransparencyNote />
          </Layout>
        }
      />
      <Route path="/story" element={<Navigate to="/?utm_source=teacher_sharing&utm_campaign=storyfeed" replace />} />
      <Route path="/flier" element={<Navigate to="/?utm_source=teacher_sharing&utm_campaign=flier" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
