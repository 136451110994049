import { useLayoutEffect } from "react";
import { create } from "zustand";
import useHelperWidgetAPI from "./useHelperWidgetAPI";

const useHelperWidgetStore = create<{
  count: number;
  add: () => void;
  subtract: () => void;
  getLatestCount: () => number;
}>((set, get) => ({
  count: 0,
  add: () => set((state) => ({ count: state.count + 1 })),
  subtract: () => set((state) => ({ count: Math.max(state.count - 1, 0) })),
  getLatestCount: () => get().count,
}));

const useHideHelperWidget = () => {
  // We use a counter to account for multiple components using this hook at the same time.
  // Without this, the widget would incorrectly unhide itself if one but not all of the components unrendered.
  const { add, subtract, getLatestCount } = useHelperWidgetStore();
  const helperWidgetAPI = useHelperWidgetAPI();

  useLayoutEffect(() => {
    if (getLatestCount() === 0) helperWidgetAPI.hideWidget();
    add();

    return () => {
      subtract();
      // To prevent flickering during route changes, I added a half-second delay before showing the widget again
      // This is hacky, but it should be enough time for the render cycle to finish and the next view to load and
      // determine if the widget should remain hidden or not.
      setTimeout(() => {
        if (getLatestCount() === 0) helperWidgetAPI.showWidget();
      }, 500);
    };
  }, [add, getLatestCount, helperWidgetAPI, subtract]);
};

export default useHideHelperWidget;
