/* eslint-disable @web-monorepo/no-nessie-typography */
// eslint-bankruptcy
// eslint-disable-next-line @web-monorepo/prefer-dds
import { BodyText, Button, HeadlineText, MinutiaeText, PasswordTextField, TextField } from "@classdojo/web/nessie";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import { FormEventHandler, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useLoginMutation, { type AuthErrorResponse } from "src/hooks/api/auth/useLoginMutation";
import { Link, useNavigate } from "src/routes";
import { DDSButton } from "@web-monorepo/dds";
import { logTutorEvent } from "src/utils/log";
import css from "./_styles.module.css";
import { test as isValidEmailAddress } from "src/validators/email";
import { getErrorMetadata } from "./getErrorMetadata";

export default function LoginPage() {
  const search = new URLSearchParams(useLocation().search);
  const [_email, setEmail] = useState(search.get("email") ?? "");
  const email = search.get("email") ?? _email;
  const redirectTo = search.get("redirectTo");
  const [password, setPassword] = useState("");
  const [loginErrorState, setLoginErrorState] = useState<ReturnType<typeof getErrorMetadata> | null>(null);
  const loginMutation = useLoginMutation();
  const navigate = useNavigate();

  const canSubmit = useMemo(() => {
    return isValidEmailAddress(email) && password.length >= 3;
  }, [email, password.length]);

  const wrongCredentials = loginErrorState?.wrongUsername || loginErrorState?.wrongPassword;
  const mustUseOtc = loginErrorState?.mustUseOtc;

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      loginMutation.mutate(
        { email, password },
        {
          onSuccess: () => {
            if (redirectTo === "direct-booking") {
              navigate(
                "/subscription/booking/select-class-frequency?directBookingFlow=true" as Parameters<typeof navigate>[0],
              );
            }
          },
          // @ts-expect-error Types of parameters 'error' and 'error' are incompatible.
          onError: (error: AxiosError<{ error?: { detail?: string } }>) => {
            setLoginErrorState(getErrorMetadata(error as AxiosError<AuthErrorResponse["content"]["application/json"]>));

            const errorMessage = error.response?.data?.error?.detail;

            if (errorMessage != null) {
              showNotification({
                title: "Login failed",
                message: errorMessage || "Server Error",
                color: "red",
              });
            } else {
              return Promise.reject(error);
            }
          },
        },
      );
    },
    [email, loginMutation, navigate, password, redirectTo],
  );

  return (
    <form className={css.wrapper} onSubmit={onSubmit}>
      <HeadlineText as="h1" level={2}>
        Log in with your email
      </HeadlineText>
      <TextField
        onChange={(value) => setEmail(value)}
        value={search.get("email") ?? email}
        placeholder="Email address"
        aria-label="Email address"
        disabled={search.get("email") != null}
      />
      <PasswordTextField
        onChange={(value) => setPassword(value)}
        value={password}
        placeholder="Password"
        aria-label="Password"
      />
      {wrongCredentials ? (
        <BodyText level={2} kind="danger">
          Invalid email or password
        </BodyText>
      ) : mustUseOtc ? (
        /* eslint-disable-next-line @web-monorepo/no-sx-property */
        <BodyText level={2} sx={{ maxWidth: 300 }}>
          Please click{" "}
          <a href="https://home.classdojo.com/login" target="_blank">
            here
          </a>{" "}
          to log into ClassDojo. You can then return here to access your Dojo Tutor account.
        </BodyText>
      ) : null}

      <Button data-name="loginButton" type="submit" disabled={!canSubmit}>
        Log in
      </Button>
      <Link to="/forgot-password" className={css.forgotPassword}>
        Forgot password?
      </Link>
      <div className={css.orWrapper}>
        <div className={css.orLine} />
        <div className={css.orText}>
          <MinutiaeText kind="tertiary">OR</MinutiaeText>
        </div>
        <div className={css.orLine} />
      </div>
      <DDSButton
        size="medium"
        data-name="parent.marketplace.login.passwordless.click"
        variant="secondary"
        onClick={() => {
          logTutorEvent({
            eventName: "parent.marketplace.login.passwordless.click",
          });
          // eslint-disable-next-line @web-monorepo/no-navigate-in-component
          navigate("/login/code");
        }}
      >
        Log in without a password
      </DDSButton>
      <BodyText className={css.signUp}>
        Don't have an account?{" "}
        <Link to="/single-class">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
          <a
            onClick={() => {
              logTutorEvent({
                eventName: "parent.marketplace.login.signup.click",
              });
            }}
          >
            Sign up
          </a>
        </Link>
      </BodyText>
    </form>
  );
}
