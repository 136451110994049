import useMutation from "#/src/hooks/useMutation";
import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/parent/session/{sessionId}/cancel"]["delete"];
type Response = Endpoint["responses"]["200"];

const cancelSession = ({ sessionId }: { sessionId: string }) =>
  monolithApiClient.delete<Response>(`/parent/session/${sessionId}/cancel`);

export default function useCancelSessionMutation() {
  return useMutation(cancelSession);
}
