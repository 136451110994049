import cn from "#/src/utils/cn";
import { WithClickOutside } from "@classdojo/web";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { Transition } from "@mantine/core";
import ReactDOM from "react-dom";
import css from "./_index.module.css";
import { useSlideSheetStore } from "./slideSheetStore";
type SlideSheetProps = {
  mounted: boolean;
  children: React.ReactNode;
  transitionDuration: number;
  timingFunction?: string;
  onClose: () => void;
  useDvh?: boolean;
};

const SlideSheet = ({
  mounted,
  children,
  transitionDuration,
  timingFunction,
  onClose,
  useDvh = false,
}: SlideSheetProps) => {
  const { assessmentOpened, selectOpened } = useSlideSheetStore((store) => ({
    assessmentOpened: store.assessmentOpened,
    selectOpened: store.selectOpened,
  }));

  return ReactDOM.createPortal(
    <div className={css.transitionWrapper}>
      <Transition
        mounted={mounted}
        transition="slide-left"
        duration={transitionDuration}
        timingFunction={timingFunction}
      >
        {(styles) => (
          <WithClickOutside
            onClickOutside={() => {
              if (!assessmentOpened && !selectOpened) onClose();
            }}
          >
            {useDvh ? (
              <div className={cn(css.slideSheet, css.dvh)} sx={styles as ThemeUIStyleObject}>
                <div className={css.scrollWrapper}>
                  <div className={cn(css.scrollContainer, css.dvh)}>
                    <div className={css.slideSheetContent}>{children}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.slideSheet} sx={styles as ThemeUIStyleObject}>
                <div className={css.scrollContainer}>
                  <div className={css.slideSheetContent}>{children}</div>
                </div>
              </div>
            )}
          </WithClickOutside>
        )}
      </Transition>
    </div>,
    document.body,
  );
};

export default SlideSheet;
