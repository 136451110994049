import { useMemo } from "react";
import useTimeSelectIntervals from "src/hooks/useTimeSelectIntervals";
import { BaseCheckbox as Checkbox, SelectInput } from "UILibrary";
import css from "./index.module.css";
import { useSlideSheetStore } from "src/components/SlideSheet/slideSheetStore";
import { IconPlus, IconTrash, IconTriangleDown } from "@web-monorepo/dds-icons";
import { UnstyledButton } from "@mantine/core";
import useWatch from "@classdojo/web/hooks/useWatch";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";
import { formatHour, calculateTimesAfter, parseDateAndTime } from "src/pageComponents/TutorDayOff/utils";

const HourSelect = ({
  onChange,
  options,
  placeholder,
  label,
  value,
  disabled,
}: {
  onChange: (val: string) => void;
  options: string[];
  placeholder: string;
  label: string;
  value?: string;
  disabled: boolean;
}) => {
  const { setSelectOpened } = useSlideSheetStore((store) => ({
    setSelectOpened: store.setSelectOpened,
  }));

  return (
    <div>
      <div className={css.selectLabel}>{label}</div>
      <SelectInput
        onChange={(value: string) => {
          setSelectOpened(true);
          onChange(value);
          setTimeout(() => {
            setSelectOpened(false);
          }, 300);
        }}
        data={options}
        rightSection={
          <div className={css.selectIcon}>
            <IconTriangleDown size="m" />
          </div>
        }
        className={css.select}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

const HourIntervalPicker = ({
  interval,
  setInterval,
  startDate,
  isOnlyInterval,
  removeInterval,
  disabled,
}: {
  interval: { startTs: Date | undefined; endTs: Date | undefined };
  setInterval: (interval: { startTs: Date | undefined; endTs: Date | undefined }) => void;
  startDate: Date;
  isOnlyInterval: boolean;
  removeInterval: () => void;
  disabled: boolean;
}) => {
  const times = useTimeSelectIntervals();

  const { startTs, endTs } = interval;

  const timesInFuture = useMemo(
    () =>
      calculateTimesAfter({
        startDate,
        after: new Date(),
        times,
      }),
    [startDate, times],
  );

  const timesAfterStart = useMemo(() => {
    if (startTs) {
      return calculateTimesAfter({ startDate, after: startTs, times });
    }
    return timesInFuture;
  }, [startDate, startTs, timesInFuture, times]);

  useWatch([startTs, endTs], () => {
    if (startTs === undefined || endTs === undefined) return;

    if (startTs >= endTs) {
      setInterval({
        startTs,
        endTs: undefined,
      });
    }
  });

  return (
    <div className={css.hourPickerWrapper}>
      <div className={css.hourSelectWrapper}>
        <HourSelect
          onChange={(value) => {
            setInterval({
              startTs: parseDateAndTime(startDate, value),
              endTs: interval?.endTs,
            });
          }}
          options={timesInFuture}
          placeholder="Start time"
          label="From"
          value={startTs ? formatHour(startTs) : ""}
          disabled={disabled}
        />
      </div>
      <div className={css.hourSelectWrapper}>
        <HourSelect
          onChange={(value) =>
            setInterval({
              startTs: interval?.startTs,
              endTs: parseDateAndTime(startDate, value),
            })
          }
          options={timesAfterStart}
          placeholder="End time"
          label="Until"
          value={endTs ? formatHour(endTs) : ""}
          disabled={disabled}
        />
      </div>

      {!isOnlyInterval ? (
        <UnstyledButton className={css.thumbButton} onClick={removeInterval} disabled={disabled}>
          <IconTrash size="s" />
        </UnstyledButton>
      ) : (
        <UnstyledButton className={`${css.thumbButton} ${css.hidden}`} disabled>
          <IconTrash size="s" />
        </UnstyledButton>
      )}
    </div>
  );
};

export default function () {
  const {
    calendarRangeState: { startDate },
    partialDaysOffFormState: { partialDayOffs, isAllDay, setIsAllDay, setInterval, removeInterval, addInterval },
  } = useDayOffFormStateContext();

  if (!startDate) return null;

  return (
    <div className={css.intervalsWrapper}>
      <div className={css.intervalsList}>
        {partialDayOffs.map((interval, idx) => (
          <HourIntervalPicker
            key={idx}
            interval={interval}
            startDate={startDate}
            setInterval={(interval) => setInterval(interval, idx)}
            isOnlyInterval={partialDayOffs.length === 1}
            removeInterval={() => removeInterval(idx)}
            disabled={isAllDay}
          />
        ))}

        <div className={css.controls}>
          <UnstyledButton className={css.addAnotherBlock} onClick={addInterval} disabled={isAllDay}>
            <IconPlus size="s" />
            Add another block
          </UnstyledButton>

          <label htmlFor="all-day-checkbox" className={css.allDayText}>
            <Checkbox
              id="all-day-checkbox"
              width={24}
              height={24}
              onChange={(event) => setIsAllDay(event.target.checked)}
              checked={isAllDay}
            />
            <span>All Day</span>
          </label>
        </div>
      </div>
    </div>
  );
}
