/* eslint-disable @web-monorepo/no-sx-property */
import { IconSettingsFilled } from "@web-monorepo/dds-icons";
import { DDSButton } from "@web-monorepo/dds";
import useBoolean from "src/hooks/useBoolean";
import OutOfOfficeSlideSheet from "./OutOfOfficeSlideSheet";
import { logTutorEvent } from "src/utils/log";

const OutOfOfficeButton = () => {
  const { isTrue: mounted, setTrue: open, setFalse: close } = useBoolean(false);
  const openDayOffSlideSheet = () => {
    logTutorEvent("marketplace.tutor.day_off_open_slidesheet_clicked");
    open();
  };

  return (
    <>
      <DDSButton
        data-name=""
        type="button"
        variant="ghost"
        sx={{ color: "dt_content_secondary" }}
        onClick={openDayOffSlideSheet}
      >
        <IconSettingsFilled size="m" />
        <div>Out of office</div>
      </DDSButton>
      <OutOfOfficeSlideSheet mounted={mounted} close={close} />
    </>
  );
};

export default OutOfOfficeButton;
