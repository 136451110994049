import { useState, useRef, useCallback, ReactNode } from "react";
// eslint-disable-next-line @web-monorepo/no-direct-react-router-imports
import { Link as ReactLink } from "react-router-dom";
import useStyles from "./styles/CoppaModal.styles";
import { Box, Modal, Text, List, BaseCheckbox, Button, Divider, Accordion } from "UILibrary";
import { useCOPPAConsentMutation } from "old/src/hooks/useParent";
import { BodyText, HeadlineText, LabelText, ListItem } from "@classdojo/web/nessie";
import { IconArrowDown, IconCheckCircleFilled, IconLockClosed, IconMonsterGroupFilled } from "@web-monorepo/dds-icons";

type CoppaModalProps = {
  onSubmit(): void;
  token?: string;
  LinkComponent?: React.ComponentType<React.ComponentProps<typeof ReactLink>>;
};

export const CoppaContent = ({ onSubmit, token, LinkComponent = ReactLink }: CoppaModalProps) => {
  const Link = LinkComponent;
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { classes } = useStyles();
  const { acceptCOPPAConsent, acceptCOPPAConsentFromToken } = useCOPPAConsentMutation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const onCoppaConsentSubmit = async () => {
    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
      await acceptCOPPAConsentFromToken.mutateAsync({ token }, {} as any);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
      await acceptCOPPAConsent.mutateAsync(undefined, {} as any);
    }
    onSubmit();
  };
  const isLoading = (token ? acceptCOPPAConsentFromToken : acceptCOPPAConsent).isLoading;

  const serviceProviderLink = useCallback(
    (plural = false) => <Link to="/third-party-service-providers/">{`service provider${plural ? "s" : ""}`}</Link>,
    [Link],
  );

  return (
    <>
      <Box m={24} sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src="images/logo/logo-light.png"
          srcSet="images/logo/logo-light@2x.png 2x"
          alt="ClassDojo logo"
          height="65px"
        />
      </Box>
      <Box mb={24}>
        <HeadlineText level={3} as="h3">
          We need a permission slip!
        </HeadlineText>
      </Box>
      <Box mb={24}>
        <SummaryItem
          title="One and done agreement"
          description="We won't ask again for any of your other kids."
          icon={<IconCheckCircleFilled size="m" sx={{ color: "#7D40FF" }} />}
        />
      </Box>
      <Box mb={24}>
        <SummaryItem
          title="We keep your child's info on lock"
          description="We'll only share it with tutors to support their learning and anyone else you choose."
          icon={<IconLockClosed size="m" sx={{ color: "#7D40FF" }} />}
        />
      </Box>
      <Box mb={24}>
        <SummaryItem
          title="Controlled group sessions"
          description="Only other participants will be able to see your child's information."
          icon={<IconMonsterGroupFilled size="m" sx={{ color: "#7D40FF" }} />}
        />
      </Box>
      <Box mb={12}>
        <Button
          onClick={() => submitButtonRef.current?.scrollIntoView({ behavior: "smooth" })}
          sx={{ backgroundColor: "#7D40FF", width: "100%" }}
        >
          <IconArrowDown size="l" sx={{ color: "white", paddingRight: "s" }} />
          Click to scroll to the bottom
        </Button>
      </Box>
      <Box mb={12}>
        <Text>
          Before you let your child log in to, use, or otherwise access their Dojo Tutor account, you should know what
          information we will collect from them. Scroll down to view our full Direct Notice disclosure, but here are the
          highlights:
        </Text>
        <List>
          <List.Item>
            You will only give consent once for any child you create an account for (including additional children you
            later create an account for) or allow access to any part of the Dojo Tutor service (the “Service”). You will
            not see this notice again for additional children you add.
          </List.Item>
          <List.Item>
            We never post or share your child's personal information publicly. Please note though that your child’s
            tutor has access to information you provide about your child. In addition, you may share your child’s
            tutoring session assessment feedback with other family members or teachers.
          </List.Item>
          <List.Item>
            If you have given your consent and your child participates in a group tutoring session, some of their
            information may be seen by other children attending the same session, but not the general public. Please see{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016402266125-What-Learner-Information-Can-be-Viewed-by-Other-Logged-in-Dojo-Tutoring-Users">
              here
            </a>{" "}
            for more details on what other children may see about your child while participating in Dojo Tutor.
            Additionally, you may decide to share your child’s tutoring session assessment feedback with other tutors,
            family members or teachers.
          </List.Item>
        </List>
      </Box>

      <Box mb={12}>
        <Box mb={8}>
          <Text>
            Before we can provide your child with access to the Service, we need your consent. Under the U.S. Children’s
            Online Privacy Protection Act and its implementing regulations under the Children's Online Privacy
            Protection Rule (collectively, “COPPA”), parental consent is required for the collection, use or disclosure
            of personal information from children under the age of 13. Dojo Tutor will not knowingly collect, use, or
            disclose your child’s personal information unless you’ve provided this consent.
          </Text>
        </Box>
        <Box mb={8}>
          <Link to="/privacy?ref=how-does-dojo-tutoring-protect-children">
            View Dojo Tutoring Privacy Notice for Children
          </Link>
        </Box>

        <Box mb={8}>
          <Text>
            This disclosure for parents ("Direct Notice") provides important information you should consider when
            allowing your child (or children) access to certain Dojo Tutor features when choosing to set up your child’s
            Dojo Tutor account or to allow them to otherwise access the Service.
          </Text>
        </Box>
        <Box mb={8}>
          <Text>
            Please note that you will only be giving consent once and that it will also apply to any children that you
            subsequently allow future access to Dojo Tutor accounts or the Service after your consent. We explain the
            information we collect for your child’s use of Dojo Tutor below. In order to create an account for your
            child or otherwise allow your child access to the Service, you must review this Direct Notice of disclosure
            (including the{" "}
            <Link to="/privacy?ref=how-does-dojo-tutoring-protect-children">
              Children’s Section of the Dojo Tutor Privacy Policy
            </Link>
            ) and give your consent by checking the box below.
          </Text>
        </Box>

        <Box mb={8}>
          <Text>
            We will also ask for your consent before we make any material changes to the practices described in this
            Direct Notice of disclosure (including the Dojo Tutor Privacy Policy). Please also see our{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            for children that highlights the most important details in our Privacy Policy that they should know about.
          </Text>
        </Box>
      </Box>

      <Text underline>Dojo Tutor may collect the following information from children using the Service:</Text>
      <Box mb={12}>
        <Accordion>
          <Accordion.Item value="name">
            <Accordion.Control>Name</Accordion.Control>
          </Accordion.Item>
          <Accordion.Item value={"Zoom Recordings of Tutoring Sessions"}>
            <Accordion.Control>{"Zoom Recordings of Tutoring Sessions"}</Accordion.Control>
            <Accordion.Panel>
              {
                <Box>
                  Certain information is captured through your child’s participation in a tutoring session, such as your
                  child’s:{" "}
                  <List>
                    <List.Item>image</List.Item>
                    <List.Item>voice</List.Item>
                    <List.Item>movements</List.Item>
                    <List.Item>audio information</List.Item>
                    <List.Item>chat logs</List.Item>
                    <List.Item>other information provided by your child during the tutoring session.</List.Item>
                  </List>
                  <Box>
                    <Text>
                      Dojo Tutor and Zoom have entered into custom contractual agreements to allow for users under 13
                      and under which Zoom has also agreed to apply its Education Account Settings to Dojo Tutor’s use
                      of Zoom. This includes treating Dojo Tutor’s use as a Zoom Education Account, applying additional
                      privacy, security and safety features and settings offered to schools to Dojo Tutor’s use of Zoom.
                      See Zoom’s Children’s Educational Privacy Statement found{" "}
                      <a href="https://explore.zoom.us/en/schools-privacy-statement/">here</a>. The tutoring sessions
                      (including transcripts and Zoom chat logs) are recorded from both {learnersLink()} (your child)
                      and tutors to provide and maintain the service (such as to create transcripts of the tutoring
                      sessions as set forth below), conduct internal analysis, and take actions to keep our platform
                      safe and secure.
                    </Text>
                  </Box>
                </Box>
              }
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Tutoring session information">
            <Accordion.Control>Tutoring session information</Accordion.Control>
            <Accordion.Panel>
              Certain tutoring session information is collected from both {learnersLink()} (your child) and tutors, such
              as tutoring subject areas, tutoring attendance and how long your child stayed in the Zoom tutoring session
              (as reported from Zoom). The attendance data is shown to both the tutor and learner (your child), to
              confirm they are following the attendance policy, and may also be shown to parents and other designated
              third parties with the learner’s (your child’s) or parent’s (your) permission.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Tutoring Session Transcripts">
            <Accordion.Control>Tutoring Session Transcripts</Accordion.Control>
            <Accordion.Panel>
              Dojo Tutor uses a {serviceProviderLink()} to create transcripts from the recorded tutoring sessions
              (“Tutoring Session Transcripts”). Dojo Tutor shares only the audio portion of the Zoom recordings of
              tutoring sessions between the learner and tutor with the service provider and ensures that the audio
              recordings are retained only transiently and deleted immediately upon creating the Tutoring Session
              Transcripts. Additionally, we ensure that the audio recordings can not be used by the service provider for
              any other purpose. Dojo Tutor also ensures that the service provider can’t retain the Tutoring Session
              Transcripts for more than thirty (30) days, unless deleted earlier by Dojo Tutor. Dojo Tutor uses these
              Tutoring Session Transcripts to perform certain automated intelligence (“AI”) related tasks as is further
              described below and in our{" "}
              <a href="https://tutor.classdojo.com/#/ai-transparency-note">AI Transparency Note.</a>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Contents of communications between your child and their tutor(s)">
            <Accordion.Control>Contents of communications between your child and their tutor(s)</Accordion.Control>
            <Accordion.Panel>
              such as messaging within the Service or information the child may communicate with their tutor(s) during a
              recorded tutoring session
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Location information, but not precise geolocation">
            <Accordion.Control>Location information, but not precise geolocation</Accordion.Control>
          </Accordion.Item>
          <Accordion.Item value="IP address">
            <Accordion.Control>
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f2f016f2-e461-4443-8c29-6bb597063447">
                IP address
              </a>
            </Accordion.Control>
          </Accordion.Item>
          <Accordion.Item value="Cookie and other similar technologies">
            <Accordion.Control>
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
                Cookie and other similar technologies
              </a>
            </Accordion.Control>
          </Accordion.Item>
          <Accordion.Item value="Device-specific information">
            <Accordion.Control>Device-specific information</Accordion.Control>
            <Accordion.Panel>
              Device-specific information, such as device ID, unique device ID, device type and device attributes (e.g.
              operating system and battery level); and a device token (to send push notifications). Zoom may also
              collect the following from the device: information about the speakers, microphone, camera, OS version,
              hard disk ID, PC name, MAC address, WiFi information, and other device information (like Bluetooth
              signals). Please see{" "}
              <a href="https://explore.zoom.us/en/schools-privacy-statement/">
                Zoom’s Children’s Education Privacy Statement
              </a>{" "}
              for more information.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Server log information and product event data">
            <Accordion.Control>Server log information and product event data</Accordion.Control>
            <Accordion.Panel>
              Server log information and product event data (e.g., if a child access a tutoring session which are used
              to track progress, for internal analysis on product usage and quality, or to send push or other
              notifications to encourage your child to take certain actions in the Dojo Tutor Service)
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box mb={8}>
        <Text>
          The device and application related data is collected from the device and application being utilized to give
          the child access to Dojo Tutor accounts (e.g., the parents device if the child is utilizing the parent’s
          device, or the child’s device if they are logging in separately on their own device). Dojo Tutor does not
          require a child to provide more information than is reasonably necessary to participate in the Services.
        </Text>
      </Box>

      <Box mb={16}>
        <Text>
          Please see <Link to="/transparency">this chart</Link> for the detailed categories of information we collect
          from each user type.
        </Text>
      </Box>

      <Text>
        <i>Additional Zoom Privacy and Safety Features Set by Dojo Tutor:</i>
      </Text>

      <Box mb={12}>
        <Accordion>
          <Accordion.Item value="Passcode-protected tutoring session">
            <Accordion.Control>Passcode-protected tutoring session</Accordion.Control>
            <Accordion.Panel>
              We require a passcode when tutors are scheduling new meetings (all meetings need to be accessed with
              passcodes). Passcodes are created and shared with the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner’s
              </a>{" "}
              parents via email so only those intended to join can access a virtual tutoring session. This passcode is
              embedded in the parent’s invite link for one-click join to Zoom. Users trying to join in from the Zoom
              link from anywhere else are required to enter the randomly generated password, which was sent to the
              parents email address.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Require Registration">
            <Accordion.Control>Require Registration</Accordion.Control>
            <Accordion.Panel>
              User registration is required: we automatically pass the learner’s information through our API integration
              that inputs the learner’s name and parents email registered in Dojo Tutor in the Zoom meeting when
              accessing the Zoom meeting through our platform. If an external user tries to use the Zoom link to access,
              they aren’t allowed access. We will see the email address of everyone who signed up to join a tutoring
              session.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Require passcode for dial-in">
            <Accordion.Control>Require passcode for dial-in</Accordion.Control>
            <Accordion.Panel>If a participant is joining via phone, they must have a passcode.</Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Disabled join before host">
            <Accordion.Control>Disabled join before host</Accordion.Control>
            <Accordion.Panel>
              Learners cannot join the tutoring session before the tutor joins and will see a pop-up that says, “The
              meeting is waiting for the host to join.”
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box mb={16}>
        <Text className={classes.modalSubtitle}>
          Information Collected from Tutors or Parents Combined with Children’s Data
        </Text>
        <Text>
          Parents and tutors may provide the information set forth below about a child which we will link or combine
          with the information listed above that we collect from the child.
        </Text>
        <Text>
          <i>Parents about their child:</i>
        </Text>
        <List>
          <List.Item>
            Name, which may be displayed to the tutor either on its own, or in a list with other {learnersLink()}{" "}
            assigned to the same tutor.
          </List.Item>
          <List.Item>Date of Birth</List.Item>
          <List.Item>A unique username and password</List.Item>
          <List.Item>
            Education information you provide about your child, such as what grade level your child is in.
          </List.Item>
          <List.Item>
            You may optionally provide your child’s school name and location; whether your child was home schooled or
            went to a private or public school; and graduation year
          </List.Item>
          <List.Item>
            Qualitative description of how your child is doing and where they need the most help. This information is
            used by the tutor to plan their tutoring session.
          </List.Item>
          <List.Item>
            Interests of your child (e.g., art, basketball, dance, swimming). Dojo Tutor uses interests you provide
            about your child to personalize their tutoring experience and provide a more customized service (including,
            but not limited to tailoring lesson plans to their interests).
          </List.Item>
          <List.Item>Languages spoken</List.Item>
          <List.Item>Gender pronouns</List.Item>
          <List.Item>
            Extra support received or student IEP needs. This information is used by the tutor to tailor the lesson plan
            according to your child’s needs.
          </List.Item>
          <List.Item>Tutoring subject areas</List.Item>
        </List>
      </Box>
      <Box mb={12}>
        <Text>
          <i>Tutors about learners (i.e. your child):</i>
        </Text>
        <List>
          <List.Item>
            Tutor’s’ assessments of the learner’s performance during the first tutoring session and over the course of
            additional sessions: Tutors will provide learners with both positive and constructive feedback that can
            further their learning (e.g., areas of strength or improvement) as well as recommended activities, resources
            and a work plan). In making these assessments, tutors may leverage the help of artificial intelligence
            generated from the Tutoring Session Transcripts, see “
            <Link to="#ai-generated-insights">Artificial Intelligence (AI)-Generated Insights on Tutoring Session</Link>
            ” section for additional information. The feedback is shared with the learner and their parents, and may be
            shared by the parent to other individuals of the parent’s choosing, but is not made available to the general
            public.
          </List.Item>
          <List.Item>
            Contents of communications between your child and their tutors - such as messaging within the Services or
            information your child may communicate with their tutor during a recorded tutoring session
          </List.Item>
          <List.Item>Tutoring attendance and how long participants stayed in Zoom (reported from Zoom)</List.Item>
        </List>
      </Box>

      <Box mb={12}>
        <Text className={classes.modalSubtitle}>How We Use the Information</Text>
        <Text>
          Information collected from children and {learnersLink()} (including {personalInformationLink()} and
          information collected automatically) is never used or disclosed for third-party advertising. We also do not
          place any{" "}
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
            third-party advertisements
          </a>{" "}
          in areas of the Service where children or learners are logged in. Additionally, personal information collected
          from children or learners is never used for{" "}
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
            behaviorally-targeted advertising
          </a>{" "}
          to children or learners by us or any third-party. We may present{" "}
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
            contextually relevant
          </a>{" "}
          educational content (not using learner personal information) for either our own products (e.g., showing a
          reading course while taking a math course) or third-party products and services ourselves that we believe may
          be of interest to a learner.
        </Text>
        <Text>Our uses of your children’s personal information are set forth below:</Text>
        <Text id="ai-generated-insights">
          <i>Artificial Intelligence (AI)-Generated Insights on Tutoring Session Transcripts</i>
        </Text>
        <Box>
          <Text>
            Dojo Tutor uses Tutoring Session Transcripts (text only) as “inputs” to generate AI insights through the use
            of a {serviceProviderLink()} that integrates a Large Language Model (“LLM”) AI subprocessor to provide this
            AI service. At the specific direction and instruction of Dojo Tutor, this LLM will process these Tutoring
            Session Transcripts in order to obtain AI-generated insights on tutoring sessions between our{" "}
            {learnersLink()} and tutors (“AI Outputs”). This processing performed on the transcripts is done through the
            use of Dojo Tutor directed instructions known as “prompts” to the LLM (e.g. “summarize the tutoring
            session”). To protect the privacy of users, Dojo Tutor does not share any direct identifiers of tutors or
            learners (except to the extent that any direct identifiers are included within the Tutoring Session
            Transcripts).
          </Text>
        </Box>
        <br />
        <Box>
          <Text>
            Data obtained from the AI Outputs and Tutoring Session Transcripts may be accessed, processed and used by
            Dojo Tutor to provide and maintain the Service, perform internal analysis, and keep our platform safe and
            secure. For example, the Tutoring Session Transcripts will be sent to the LLM to create automated tutoring
            assessments, lesson summaries, and learning plans. To understand more about the specific “prompts” used to
            generate these AI insights, the information shared with this LLM, and Dojo Tutor’s use of AI, please see our{" "}
            <Link to="/ai-transparency-note">AI Transparency Note</Link>.
          </Text>
        </Box>
        <br />
        <Box mb={12}>
          <Text>
            <b>
              Dojo Tutor does not use any personal information contained in any Tutoring Session Transcripts to train
              any Dojo Tutor’s artificial intelligence models. Dojo Tutor will only train our models with data that has
              been {deIdentifiedLink()}. Additionally, Dojo Tutor does not allow any LLM {serviceProviderLink()} or
              other AI third-party to use any information (personal information or de-identified) to train, improve or
              develop their artificial intelligence models.
            </b>{" "}
            This commitment includes the information contained in the charts detailed in this{" "}
            <Link to="/ai-transparency-note">AI Transparency Note</Link>, such as the Tutoring Session Transcripts, used
            to provide the AI Outputs. For retention of this information, please see this{" "}
            <Link to="/transparency">chart</Link>.
          </Text>
        </Box>
        <Box>
          <Text>
            <i>Non-AI Uses</i>
          </Text>
          <Text>
            In addition to the uses mentioned above, we use the information we collect from children (or about children
            from parents and tutors):
          </Text>
        </Box>
        <List>
          <List.Item>
            To provide and improve (as allowed by law) the Service, including administering our tutoring sessions, to
            send push notifications or other in-app notifications to your child (e.g. to encourage them to take certain
            actions in the Service)
          </List.Item>
          <List.Item>For internal analytics on product usage</List.Item>
          <List.Item>
            For <a href="https://classdojo.zendesk.com/hc/articles/115004741703#EducationalPurposes">educational</a>{" "}
            purposes and as allowed by law
          </List.Item>
          <List.Item>To identify, fix and troubleshoot bugs and Service errors</List.Item>
          <List.Item>For security and safety purposes, including enforcing our Terms</List.Item>
          <List.Item>To comply with legal process or other laws</List.Item>
        </List>
      </Box>
      <Box mb={12}>
        <Text className={classes.modalSubtitle}>Disclosure of Information</Text>
        <Text>
          We do not disclose any {personalInformationLink()} about children to third-parties, except to{" "}
          {serviceProviderLink(true)} (e.g., Zoom, AI service providers) necessary to provide the Service, other users
          as part of the Service (such as tutors, other learners from your group tutoring session), as directed or
          approved by the child’s parents or legal guardians, as required by law, or to protect the security of the
          Service or other users.
        </Text>
      </Box>

      <Box mb={12}>
        <Text>
          We may share aggregated or {deIdentifiedLink()} information, which cannot reasonably be used to identify your
          child, with third parties for various purposes, including compliance with various reporting obligations; to
          demonstrate how the Service is used; for business purposes; or to assist third parties in understanding our
          users’ interest, habits, and usage patterns for certain programs.
        </Text>
      </Box>

      <Box mb={12}>
        <Text className={classes.modalSubtitle}>Visibility of Children’s Personal Information</Text>
        <Text>
          Currently, learners access the Services through a parent setting up the learner’s account and/or giving their
          child access to the Services through the parent’s device (“Learner Account”). No Learner Account, profile or{" "}
          {personalInformationLink()} of a child is made available or visible to the general public through Dojo Tutor.
          Any content uploaded by you, your child, or your child’s tutor (including tutor and learner comments,
          assessments, or Zoom video recordings of tutoring sessions) through the Service, can be viewed by you, those
          you choose to share assessments or any other tutoring information with, and your child’s tutor. Information
          generated by AI systems or models will only be shared with you, your child, and your child’s tutor.
        </Text>
        <Text>
          If you choose to enroll your child in a group tutoring session, any other child or learner in that tutoring
          session will also be able to see your child’s first name as well as any information another learner in that
          group tutoring session may share during the session (including through the chat feature). For group tutoring
          sessions, please note that other children will not be able to view your child’s assessment or other tutoring
          information.
        </Text>
        <Text>
          Please see{" "}
          <a href="https://classdojo.zendesk.com/hc/articles/12016402266125-What-Learner-Information-Can-be-Viewed-by-Other-Logged-in-Dojo-Tutoring-Users">
            here
          </a>{" "}
          for more details on what other children and users may see about your child while engaging in Dojo Tutor and
          how children can interact with each other and other users. .
        </Text>
      </Box>

      <Box mb={12}>
        <Text className={classes.modalSubtitle}>Retention of Children’s Personal Information</Text>
        <Text>
          Dojo Tutor will not retain a child’s {personalInformationLink()} for any longer than is reasonably necessary
          to fulfill the purpose for which the information was collected, including, to provide and maintain the Service
          for which we collect the child’s personal information, perform internal analysis, keep our platform safe and
          secure, or comply with legal obligations. Additionally, we only keep a child’s personal information for as
          long as their Learner Account is <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>
          , unless we are required by law to retain it, need it to ensure the security of our community or our Service,
          or to enforce our Terms. Specifically, we implement the following policies:
        </Text>
        <Accordion>
          <Accordion.Item value="Deleting inactive Learner Accounts">
            <Accordion.Control>Deleting inactive Learner Accounts</Accordion.Control>
            <Accordion.Panel>
              If a child’s Learner Account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">inactive</a> for twelve months or more
              (meaning no learner (or their parent on behalf of their child) has logged into the Learner Account within
              the last 365 days), Dojo Tutor will delete the Learner Account. Learn more about how we delete inactive
              Learner Accounts <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">here</a>. For more
              information on the deletion of content related to Learner Accounts, please see our{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQ</a>.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="14-day deletion policy for tutoring session recordings on Zoom">
            <Accordion.Control>14-day deletion policy for tutoring session recordings on Zoom</Accordion.Control>
            <Accordion.Panel>
              To protect learners, Dojo Tutor sets limits on how long the Zoom recordings of tutoring sessions are
              maintained. These recordings are automatically deleted after 14 days by Zoom, unless we are required by
              law to retain it, need it to ensure the security of our community or our Service, or to enforce our Terms.
              The Zoom video recordings are stored on Zoom’s hosting provider’s servers. To learn more, read the{" "}
              <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Retention policy for tutoring session recordings in the Service">
            <Accordion.Control>Retention policy for tutoring session recordings in the Service</Accordion.Control>
            <Accordion.Panel>
              Separately, within the 14-day period in which Zoom retains these recordings, Dojo Tutor may either
              download, store and retain the recordings (or elect to have Zoom retain these) for up to an additional 30
              days to provide and maintain the service, conduct internal analysis, and keep our platform safe and
              secure.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Tutoring Session Transcripts">
            <Accordion.Control>Tutoring Session Transcripts</Accordion.Control>
            <Accordion.Panel>
              The audio only portion of Zoom recordings of tutoring sessions are retained only transiently by the{" "}
              {serviceProviderLink()} and deleted immediately upon creating the Tutoring Session Transcripts.
              Additionally, Dojo Tutor ensures that its service provider can’t retain the Tutoring Session Transcripts
              for more than thirty (30) days, unless deleted earlier by Dojo Tutor. Dojo Tutor then receives and retains
              the Tutoring Session Transcripts for only as long as the Learner’s Account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by
              law to retain them, need them to enforce our policies or to ensure the security of our community.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Artificial Intelligence Input and Output">
            <Accordion.Control>Artificial Intelligence Input and Output</Accordion.Control>
            <Accordion.Panel>
              Your child’s data used to provide the “inputs” (e.g., the Tutoring Session Transcripts) may be temporarily
              retained by the LLM and related {serviceProviderLink(true)}, but in no event longer than thirty (30) days.
              Additionally, any AI Outputs will also be deleted by the {serviceProviderLink(true)} within thirty (30)
              days of performing the processing on the “inputs” (e.g,. the Tutoring Session Transcripts). Dojo Tutor
              then receives and retains this AI Output for only as long as the Learner’s Account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by
              law to retain it, need it to enforce our policies or to ensure the security of our community.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Text>
          Please see our <Link to="/transparency">Information Transparency chart</Link> and{" "}
          <Link to="/ai-transparency-note">AI Transparency Note</Link> for more details on specific retention periods.
          Additionally, for more information on the deletion of content related to Learner Accounts, please see our{" "}
          <a href="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQ</a>.
        </Text>
        <Text>
          We may retain {deIdentifiedLink()} information for as long as we deem appropriate or as otherwise required by
          law.
        </Text>
      </Box>

      <Box mb={12}>
        <Text className={classes.modalSubtitle}>Parental Choices and Controls</Text>
        <Text>At any time you have the right to:</Text>
        <List>
          <List.Item>Access, review and correct your child’s personal information;</List.Item>
          <List.Item>Direct us to delete your child’s personal information;</List.Item>
          <List.Item>
            Refuse to permit us to collect further personal information from your child online and request that we
            delete the personal information that we have already collected from your child; and
          </List.Item>
          <List.Item>
            Agree to the collection and use of your child’s personal information, but choose not to allow disclosure to
            certain third parties (unless disclosure is integral to the Service)
          </List.Item>
        </List>
        <Text>
          Please keep in mind that a request to delete information or refusal to permit disclosure of the information to
          third parties may affect your child’s ability to access the Services or use certain features. To exercise any
          of these rights, please contact us at{" "}
          <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> or 735 Tehama Street, San
          Francisco, CA 94103. Please be aware that to protect children’s privacy and security, we take reasonable steps
          to verify a parent or legal guardian’s identity before gaining access to any of their child’s personal
          information. For more information about exercising your rights, visit the “
          <Link to="/privacy?ref=parental-choices">Parental Choices</Link>” section of the Dojo Tutor Privacy Policy.
        </Text>
      </Box>

      <Text>
        *Note that for “technological reasons,” we may collect IP address and device-specific information (including
        device tokens used for sending push notifications) prior to obtaining your consent, but will only use this
        information for the purpose of providing support for the internal operations of our Service prior to receiving
        your consent. We will also not send out any push notifications until parental consent is given and if we do not
        receive parental consent, we will delete the device tokens within 7 days of collection.
      </Text>
      <Box mt={12} mb={12}>
        <Divider />
      </Box>
      <Box className={classes.checkboxContainer}>
        <BaseCheckbox
          className={classes.checkboxInput}
          onChange={(event: { currentTarget: { checked: boolean } }) => setTermsAccepted(event.currentTarget.checked)}
        />
        <Text>
          I agree to the above terms and consent to the use of my child's personal information to be used by Dojo Tutor
          with the Services.
        </Text>
      </Box>

      <Box className={classes.continueButtonContainer}>
        <Button
          className={classes.continueButton}
          onClick={onCoppaConsentSubmit}
          disabled={!termsAccepted || isLoading}
          ref={submitButtonRef}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

const learnersLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
    learners
  </a>
);
const personalInformationLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
    personal information
  </a>
);

const deIdentifiedLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
    de-identified
  </a>
);

const SummaryItem = ({ title, description, icon }: { title: string; description: string; icon: ReactNode }) => (
  <ListItem
    leftAccessory={
      <div
        sx={{
          height: 48,
          width: 48,
          backgroundColor: "#F5EBFF",
          borderRadius: "dt_radius_round",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "s",
        }}
      >
        {icon}
      </div>
    }
    title={<LabelText level={2}>{title}</LabelText>}
    description={
      <BodyText level={2} sx={{ color: "dt_content_secondary" }}>
        {description}
      </BodyText>
    }
  />
);

const CoppaModal = (props: CoppaModalProps) => {
  const { classes } = useStyles();

  return (
    <Modal radius={24} opened={true} withCloseButton={false} onClose={() => {}} size={500}>
      <Box p={2}>
        <Box className={classes.modalBodyContainer}>
          <CoppaContent {...props} />
        </Box>
      </Box>
    </Modal>
  );
};

export default CoppaModal;
