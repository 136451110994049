import {
  type MutateOptions,
  type MutationFunction,
  type UseMutationResult,
  // eslint-disable-next-line @web-monorepo/no-use-raw-onMutate
  useMutation as useMutationRQ,
} from "@tanstack/react-query";

type MutateOptionsStrict<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
  TOptions = MutateOptions<TData, TError, TVariables, TContext>,
> = Omit<TOptions, "onError"> & {
  onError: NonNullable<MutateOptions<TData, TError, TVariables, TContext>["onError"]>;
};

type UseMutationStrict<TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown> = Omit<
  UseMutationResult<TData, TError, TVariables, TContext>,
  "mutate"
> & {
  mutateAsync: (
    variables: TVariables,
    options: MutateOptionsStrict<TData, TError, TVariables, TContext>,
  ) => Promise<TData>;
  mutate: (variables: TVariables, options: MutateOptionsStrict<TData, TError, TVariables, TContext>) => void;
};

const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
  TOptions = MutateOptions<TData, TError, TVariables, TContext>,
  TReturn = TOptions extends { onError: NonNullable<unknown> }
    ? UseMutationResult<TData, TError, TVariables, TContext>
    : UseMutationStrict<TData, TError, TVariables, TContext>,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: TOptions,
) =>
  // @ts-expect-error No overload matches this call.
  useMutationRQ(mutationFn, options as MutateOptions<TData, TError, TVariables, TContext>) as TReturn;

export default useMutation;
