/* eslint-disable @web-monorepo/no-sx-property, @web-monorepo/automatic-translations, @web-monorepo/no-nessie-typography */

import { BodyText } from "@classdojo/web/nessie";
import { Path, Link } from "#/src/routes";
import { useLocation } from "react-router-dom";
import Logo from "./Logo";
import useStyles from "./styles/Header.styles";
import { ActionIcon, Avatar, Divider, Menu, TimezoneText, ChevronDown, Logout, Mail, Box, Burger } from "UILibrary";
import useLogoutMutation from "src/hooks/api/auth/useLogoutMutation";
import { useSessionQuery } from "src/hooks/useSession";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";
import marketplaceTheme from "src/utils/marketplaceTheme";
import BadgedMegaphone from "./BadgedMegaphone";
import useIsMobile from "#/src/hooks/useIsMobile";

type PrivateHeaderProps = {
  navbarOpened?: boolean;
  toggleNavbar?(): void;
};

const PrivateHeaderContents = ({ navbarOpened, toggleNavbar }: PrivateHeaderProps) => {
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const burgerTitle = navbarOpened ? "Open navigation" : "Hide navigation";
  const logoutMutation = useLogoutMutation();
  const { sessionData } = useSessionQuery();
  const helperWidgetAPI = useHelperWidgetAPI();
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "dt_xl" }}>
        <Logo href="/teachers/calendar" />
        {!isMobile && (
          <Box className={classes.privateHeaderTabs} sx={{ gap: "dt_l" }}>
            <Link
              className={cx(classes.privateHeaderTabLink, {
                [classes.privateHeaderTabLinkActive]: pathname === "/teachers/calendar",
              })}
              to="/teachers/calendar"
            >
              Classes Calendar
            </Link>
            <Link
              className={cx(classes.privateHeaderTabLink, {
                [classes.privateHeaderTabLinkActive]: pathname === "/teachers/availability",
              })}
              to={"/teachers/availability" as Path}
            >
              Availability
            </Link>
            <Link
              className={cx(classes.privateHeaderTabLink, {
                [classes.privateHeaderTabLinkActive]: pathname === "/teachers/profile",
              })}
              to="/teachers/profile"
            >
              Profile
            </Link>
          </Box>
        )}
      </Box>
      {!isMobile && (
        <Box className={classes.headerActions}>
          <Menu>
            <BadgedMegaphone />
            <Menu.Target>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "var(--sp-9)",
                }}
              >
                <Avatar
                  src={null}
                  alt="ClassDojo avatar"
                  radius="xl"
                  sx={
                    {
                      div: {
                        color: marketplaceTheme.colors.dt_content_accent,
                        backgroundColor: marketplaceTheme.colors.dt_background_accent,
                      },
                    } as any
                  }
                >
                  {sessionData?.user_details.email.charAt(0).toUpperCase()}
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <BodyText level={2}>
                    {sessionData?.user_details.first_name} {sessionData?.user_details.last_name}
                  </BodyText>
                  <TimezoneText />
                </Box>
                <ActionIcon ml={16}>
                  <ChevronDown sx={{ cursor: "pointer" }} color="#7174A0" size={18} />
                </ActionIcon>
              </Box>
            </Menu.Target>

            <Menu.Dropdown sx={{ width: "160px" }}>
              <Menu.Item
                onClick={() =>
                  helperWidgetAPI.openWidget({
                    email: sessionData?.user_details.email,
                    name: `${sessionData?.user_details.first_name || ""} ${sessionData?.user_details.last_name || ""}`,
                  })
                }
                icon={<Mail size={14} />}
              >
                Contact Us
              </Menu.Item>
              <Divider />
              <Menu.Item onClick={() => logoutMutation.mutateAsync()} icon={<Logout size={14} />}>
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      )}
      {isMobile && (
        <Box className={classes.mobileHeaderActions}>
          <BadgedMegaphone />
          <Burger
            opened={navbarOpened || false}
            size="sm"
            onClick={toggleNavbar}
            title={burgerTitle}
            aria-label={burgerTitle}
          />
        </Box>
      )}
    </Box>
  );
};

export default PrivateHeaderContents;
