import { useGlobalStore } from "#/old/src/store";
import { actionTypes } from "#/old/src/store/actionTypes";
import { useModalStore } from "#/src/components/Modals";
import ModalWrapper from "#/src/components/Modals/ModalWrapper";
import useIsMobile from "#/src/hooks/useIsMobile";
import CollageImage from "#/src/pages/_components/NewLandingPage/HeroSection/Collage.png";
import { DDSBodyText, DDSButton, DDSHeadlineText } from "@web-monorepo/dds";
import { useNavigate } from "react-router-dom";
import css from "./index.module.css";
const TutorUnavailableModal = () => {
  const push = useNavigate();
  const hideModal = useModalStore((state) => state.hideModal);
  const [, dispatch] = useGlobalStore();
  const handleClaimFreeTrial = () => {
    hideModal();
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state.booking,
        replacementForSession: state?.booking?.subscriptionSessionId,
      },
    });
    push("/additional-single-class/step-six");
  };
  const [state] = useGlobalStore();
  const { booking } = state;

  const isMobile = useIsMobile();
  const modalVariant = isMobile ? "bottomDrawer" : "centered";
  return (
    <ModalWrapper variant={modalVariant}>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "dt_l",
          padding: "dt_xs",
          textAlign: "center",
        }}
      >
        <img alt="tutor and student collage" src={CollageImage} width={240} />
        <DDSHeadlineText className={css.noMargin} level={2}>
          Try another tutor on us!
        </DDSHeadlineText>
        <DDSBodyText level={1}>
          {`We're sorry, but ${booking?.teacher?.first_name || "the tutor you selected"} is no longer available. We've picked similar tutors for you to take a trial class with for free.`}
        </DDSBodyText>

        <DDSButton data-name="tutor-unavailable-modal:claim-free-trial" onClick={handleClaimFreeTrial} width="fill">
          Claim your free trial
        </DDSButton>
      </div>
    </ModalWrapper>
  );
};

export default TutorUnavailableModal;
