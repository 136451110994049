import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { addDays, getLocalizedDateTime } from "src/utils/dates";
import { useTeacherMutation } from "old/src/hooks/useTeacher";
import { logTutorEvent } from "src/utils/log";
import useWatch from "@classdojo/web/hooks/useWatch";
import useBoolean from "src/hooks/useBoolean";
import { getDayOffType, isDayOffTooClose, parseDateAndTime } from "src/pageComponents/TutorDayOff/utils";

const MAX_ALLOWED_DAYS_OFF = 14;
const todayAtMidnight = new Date();
todayAtMidnight.setHours(0, 0, 0, 0);

interface RangeCalendarState {
  startDate: Date | null;
  endDate: Date | null;
  calendarVisible: boolean;
  settingStartDate: boolean;
}

const useCreateDayOff = (selectedDayOffs: { startTs: Date; endTs: Date }[], onSuccess: (reason: string) => void) => {
  const { updateDaysOff } = useTeacherMutation();
  const createDayOff = useCallback(
    (reason: string) => {
      updateDaysOff.mutate(
        selectedDayOffs.map(({ startTs, endTs }) => ({
          start_ts: startTs.toISOString(),
          end_ts: endTs.toISOString(),
          reason,
        })),
        {
          onSuccess: () => onSuccess(reason),
          onError: () => {
            logTutorEvent("marketplace.tutor.day_off_creation_failed");
          },
        },
      );
    },
    [selectedDayOffs, updateDaysOff, onSuccess],
  );

  return { createDayOff, updateDaysOff };
};

const useRangeCalendarState = () => {
  const [state, setState] = useState<RangeCalendarState>({
    startDate: getLocalizedDateTime(),
    endDate: getLocalizedDateTime(),
    calendarVisible: false,
    settingStartDate: true,
  });
  const { settingStartDate, startDate, endDate } = state;

  const minDate = settingStartDate ? todayAtMidnight : (startDate ?? todayAtMidnight);

  const hideCalendar = useCallback(() => {
    setState((prev) => ({ ...prev, calendarVisible: false }));
  }, []);

  const onStartInputClick = useCallback(() => {
    setState((prev) =>
      prev.calendarVisible
        ? { ...prev, calendarVisible: false }
        : { ...prev, settingStartDate: true, calendarVisible: true },
    );
  }, []);

  const onEndInputClick = useCallback(() => {
    setState((prev) =>
      prev.calendarVisible
        ? { ...prev, calendarVisible: false }
        : { ...prev, settingStartDate: prev.startDate == null, endDate: null, calendarVisible: true },
    );
  }, []);

  const onDateSelected = (date: Date) => {
    if (minDate != null && date < minDate) return;

    setState((prev) => ({
      ...prev,
      settingStartDate: false,
      startDate: prev.settingStartDate ? date : prev.startDate,
      endDate: !prev.settingStartDate ? date : prev.endDate != null && date > prev.endDate ? null : prev.endDate,
      calendarVisible: prev.settingStartDate,
    }));
  };
  const isSameDay = !!startDate && !!endDate && startDate.getTime() === endDate.getTime();

  const resetState = useCallback(() => {
    setState({
      startDate: null,
      endDate: null,
      calendarVisible: false,
      settingStartDate: true,
    });
  }, []);

  return {
    ...state,
    onStartInputClick,
    onEndInputClick,
    hideCalendar,
    onDateSelected,
    minDate,
    isSameDay,
    resetState,
  };
};

const usePartialDaysOfFormState = () => {
  const [partialDayOffs, setPartialDayOffs] = useState<{ startTs: Date | undefined; endTs: Date | undefined }[]>([
    { startTs: undefined, endTs: undefined },
  ]);
  const [isAllDay, setIsAllDay] = useState(false);

  const setInterval = useCallback(
    (interval: { startTs: Date | undefined; endTs: Date | undefined }, idx: number) => {
      const newIntervals = [...partialDayOffs];
      newIntervals[idx] = interval;
      setPartialDayOffs(newIntervals);
    },
    [partialDayOffs],
  );

  const removeInterval = useCallback(
    (idx: number) => {
      const newIntervals = [...partialDayOffs].filter((_, i) => i !== idx);
      setPartialDayOffs(newIntervals);
    },
    [partialDayOffs],
  );

  const addInterval = useCallback(() => {
    setPartialDayOffs([...partialDayOffs, { startTs: undefined, endTs: undefined }]);
  }, [partialDayOffs]);

  const resetState = useCallback(() => {
    setPartialDayOffs([{ startTs: undefined, endTs: undefined }]);
    setIsAllDay(false);
  }, [setPartialDayOffs, setIsAllDay]);

  return {
    partialDayOffs,
    setPartialDayOffs,
    isAllDay,
    setIsAllDay,
    resetState,
    setInterval,
    addInterval,
    removeInterval,
  };
};

const useDayOffFormState = () => {
  const [step, setStep] = useState<number>(0);
  const { isTrue: successBannerVisible, setTrue: showSuccessBanner, setFalse: hideSuccessBanner } = useBoolean(false);

  const cancel = useCallback(() => setStep(0), [setStep]);
  const goBack = useCallback(() => setStep(1), [setStep]);

  const calendarRangeState = useRangeCalendarState();
  const partialDaysOffFormState = usePartialDaysOfFormState();
  const { startDate, endDate, isSameDay, resetState: resetCalendarRangeState } = calendarRangeState;
  const { partialDayOffs, isAllDay, resetState: resetPartialDaysOffState } = partialDaysOffFormState;

  useWatch([step, hideSuccessBanner], () => {
    if (step != 0) {
      hideSuccessBanner();
    }
  });

  const durationIsTooLong = useMemo(() => {
    if (startDate == null || endDate == null) return false;
    const durationInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    return durationInDays > MAX_ALLOWED_DAYS_OFF;
  }, [startDate, endDate]);

  const goToNextStep = useCallback(() => {
    setStep((step) => {
      if (step == 1) {
        if (durationIsTooLong) {
          logTutorEvent("marketplace.tutor.day_off_creation_duration_too_long");
          return 3;
        } else {
          logTutorEvent("marketplace.tutor.day_off_creation_confirmation_with_category");
          return 2;
        }
      }
      if (step === 0) {
        logTutorEvent("marketplace.tutor.day_off_add_ooo_clicked");
      }

      return step + 1;
    });
  }, [setStep, durationIsTooLong]);

  const selectedDayOffs = useMemo(() => {
    if (!startDate || !endDate) return [];
    if (isSameDay) {
      if (isAllDay) {
        return [
          {
            startTs: parseDateAndTime(startDate, "12:00 AM"),
            endTs: parseDateAndTime(addDays(endDate, 1), "12:00 AM"),
          },
        ];
      }
      return partialDayOffs.filter(({ startTs, endTs }) => startTs !== undefined && endTs !== undefined) as {
        startTs: Date;
        endTs: Date;
      }[];
    } else {
      return [{ startTs: startDate, endTs: addDays(endDate, 1) }];
    }
  }, [startDate, endDate, isAllDay, isSameDay, partialDayOffs]);

  const resetFormState = useCallback(() => {
    cancel();
    resetCalendarRangeState();
    resetPartialDaysOffState();
  }, [cancel, resetCalendarRangeState, resetPartialDaysOffState]);

  useWatch([startDate, endDate, resetPartialDaysOffState], () => {
    resetPartialDaysOffState();
  });

  const onSuccess = useCallback(
    (reason: string) => {
      logTutorEvent({
        eventName: "marketplace.tutor.day_off_creation_success",
        metadata: {
          dayOffCategory: reason,
          dayOffType: getDayOffType(selectedDayOffs),
          dayOffIsTooClose: isDayOffTooClose(selectedDayOffs),
        },
      });
      showSuccessBanner();
      resetFormState();
    },
    [showSuccessBanner, resetFormState, selectedDayOffs],
  );

  const { createDayOff, updateDaysOff } = useCreateDayOff(selectedDayOffs, onSuccess);
  return {
    calendarRangeState,
    partialDaysOffFormState,
    selectedDayOffs,
    step,
    successBannerVisible,
    showSuccessBanner,
    hideSuccessBanner,
    setStep,
    createDayOff,
    updateDaysOff,
    resetFormState,
    cancel,
    goBack,
    goToNextStep,
  };
};

type DayOffFormState = ReturnType<typeof useDayOffFormState>;

export const DayOffFormStateContext = createContext<DayOffFormState>(undefined as any);

export const useDayOffFormStateContext = () => useContext(DayOffFormStateContext);

export default useDayOffFormState;
