import { getDayName, type DAY_KEYS } from "#/src/utils/dayOfWeek";
import { addDays, addHours, addMinutes, isDuringBusinessHours, startOf } from "#/src/utils/dates";
import uniq from "lodash/uniq";

export type TimeRange = (typeof TIME_RANGE_OPTIONS)[number];
export const TIME_RANGE_OPTIONS = [
  {
    name: "Morning",
    label: "8 AM - 12 PM",
    hours: [8, 9, 10, 11],
    startTime: "8:00 AM",
    endTime: "12:00 PM",
  },
  {
    name: "Afternoon",
    label: "12 - 3 PM",
    hours: [12, 13, 14],
    startTime: "12:00 PM",
    endTime: "3:00 PM",
  },
  {
    name: "After School",
    label: "3 - 6 PM",
    hours: [15, 16, 17],
    startTime: "3:00 PM",
    endTime: "6:00 PM",
  },
  {
    name: "Evening",
    label: "6 - 10 PM",
    hours: [18, 19, 20, 21],
    startTime: "6:00 PM",
    endTime: "10:00 PM",
  },
] as const;

export const deriveDatesFromDaysAndTimeRanges = (days: DAY_KEYS[], timeRangesValues: TimeRange["name"][]) => {
  const timeRanges = TIME_RANGE_OPTIONS.filter(({ name }) => timeRangesValues.includes(name));
  const hours = uniq(timeRanges.map(({ hours }) => hours).flat());
  const dates: Date[] = [];
  let dateReference = startOf(addDays(new Date(), 1), "day");

  for (let i = 0; i < 7; i++) {
    if (days.includes(getDayName(dateReference))) {
      hours.forEach((hour) => {
        const dateAtHour = addHours(dateReference, hour);
        if (isDuringBusinessHours(dateAtHour)) {
          dates.push(dateAtHour);
        }
        const dateAtHalfHour = addMinutes(dateAtHour, 30);
        if (isDuringBusinessHours(dateAtHalfHour)) {
          dates.push(dateAtHalfHour);
        }
      });
    }
    dateReference = addDays(dateReference, 1);
  }

  return dates;
};
