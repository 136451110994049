import { useCallback } from "react";
import { useGlobalStore, type BookingType } from "old/src/store";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import css from "./CalendarAndTimesForTutor.module.css";
import TimezoneSelect from "#/src/components/TimezoneSelect";
import HorizontalLine from "#/src/components/HorizontalLine";
import { IconCalendar, IconClock, IconPinLocation } from "@web-monorepo/dds-icons";
import { DDSLabelText } from "@web-monorepo/dds";
import SelectDate from "#/src/pageComponents/RescheduleSession/SelectDate";
import SelectTime from "#/src/pageComponents/RescheduleSession/SelectTime";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useWatch from "@classdojo/web/hooks/useWatch";
import { z } from "zod";
import {
  addDays,
  formatWithPattern,
  getDefaultTimezone,
  parseIsoStringInTimeZone,
  parseWithPattern,
} from "#/src/utils/dates";
import { actionTypes } from "#/old/src/store/actionTypes";
import useScheduleSlotsByTutorQuery from "#/src/hooks/api/tutor/useScheduleSlotsByTutorQuery";
import { usePublicTeacherProfileQuery } from "#/old/src/hooks/useParent";
import ProfilePhoto from "#/src/components/ProfilePhoto";
import { useOnFirstRender } from "@web-monorepo/hooks";
import { usePrevious } from "@classdojo/web";
import useScrollToTopOnLoad from "#/src/hooks/useScrollToTopOnLoad";
import { useLogEventForAvailableSlots } from "./DateTimeStep/useAvailableSpotsByDate";

type Props = {
  onBack: () => void;
  onNext: (updatedBooking: BookingType) => void;
};

export const ClassLogisticsLine = () => (
  <div className={css.classLogisticsLine}>
    <div>
      <div className={css.icon}>
        <IconClock color="var(--content-accent)" />
      </div>
      <DDSLabelText level={3}>25 min</DDSLabelText>
    </div>
    <div>
      <div className={css.icon}>
        <IconPinLocation color="var(--content-accent)" />
      </div>
      <DDSLabelText level={3}>Online</DDSLabelText>
    </div>
    <div>
      <div className={css.icon}>
        <IconCalendar color="var(--content-accent)" />
      </div>
      <DDSLabelText level={3}>Easy reschedule</DDSLabelText>
    </div>
  </div>
);

export const useBookingTimezone = () => {
  const [state] = useGlobalStore();
  const timezone = state.booking?.timezone ?? getDefaultTimezone();

  return timezone;
};

const TimeZoneDropdown = () => {
  const [state, dispatch] = useGlobalStore();
  const timezone = useBookingTimezone();
  const setTimezone = (timezone: string) => {
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: { ...state.booking, timezone },
    });
  };

  return (
    <div className={css.timezoneWrapper}>
      <div>Timezone:</div>
      <div className={css.timezoneSelectWrapper}>
        <TimezoneSelect selectedValue={timezone} onChange={setTimezone} />
      </div>
    </div>
  );
};

export const SelectedDate = ({ date }: { date: Date }) => {
  const timezone = useBookingTimezone();
  const formattedDate = formatWithPattern(date, "EEE, MMMM d", { timeZone: timezone });
  return <DDSLabelText level={1}>{formattedDate}</DDSLabelText>;
};

const FormSchema = z.object({
  date: z.string(),
  time: z.string(),
});
type FormData = Partial<z.infer<typeof FormSchema>>;

export const useAvailableSlotsGroupedByDate = (availableSlots: string[] | undefined) => {
  const timezone = useBookingTimezone();
  return (availableSlots ?? []).reduce(
    (result, slot) => {
      const time = parseIsoStringInTimeZone(slot, timezone);
      const date = formatWithPattern(time, "yyyy-MM-dd");
      const dayOfWeek = formatWithPattern(time, "EEE");

      if (!result[date]) {
        result[date] = {
          day: formatWithPattern(time, "PP"),
          slots: [],
          dayOfWeek,
        };
      }

      result[date].slots.push({
        classStartDate: slot,
        startTimeLabel: formatWithPattern(time, "h:mm a"),
      });

      return result;
    },
    {} as Record<
      string,
      { day: string; dayOfWeek: string; slots: Array<{ classStartDate: string; startTimeLabel: string }> }
    >,
  );
};

const now = new Date();
const CalendarAndTimesForTutor = ({ onBack, onNext }: Props) => {
  const title = "Pick a date for the trial class";
  const [state, dispatch] = useGlobalStore();
  const tutorId = state.booking?.teacher_uid;
  const timezone = useBookingTimezone();

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: { date: "", time: "" },
  });

  useOnFirstRender(() => {
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state.booking,
        date: undefined,
        time: undefined,
      },
    });
  });

  const { availableSlots } = useScheduleSlotsByTutorQuery(
    tutorId
      ? {
          tutorId,
          startDate: now.toISOString(),
          endDate: addDays(now, 7).toISOString(),
        }
      : undefined,
  );

  const { teacher: tutor } = usePublicTeacherProfileQuery(tutorId);
  const slotsByDate = useAvailableSlotsGroupedByDate(availableSlots);
  const availableDays = Object.values(slotsByDate);

  const selectedDateString = form.watch("date");
  const selectedDate =
    selectedDateString === "" ? null : parseWithPattern(selectedDateString, "MMMM d, yyyy", undefined, timezone);

  useScrollToTopOnLoad();

  const date = form.watch("date");
  const time = form.watch("time");
  const parsedTime = time ? parseIsoStringInTimeZone(time, timezone) : null;
  const formattedTime = parsedTime ? formatWithPattern(parsedTime, "hh:mm a") : null;

  useLogEventForAvailableSlots(parsedTime, timezone, availableSlots);

  const onSubmit = useCallback(() => {
    if (selectedDate == null) return;
    const updatedBooking = { ...state.booking, date, time: formattedTime, timezone } as BookingType;

    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: updatedBooking,
    });

    onNext(updatedBooking);
  }, [selectedDate, state.booking, dispatch, onNext, timezone, date, formattedTime]);

  const disabled = form.watch("date") === "" || form.watch("time") === "";

  const previousSelectedDateString = usePrevious(selectedDateString);
  useWatch([previousSelectedDateString, selectedDateString], () => {
    if (selectedDateString !== "" && selectedDateString !== previousSelectedDateString) {
      form.setValue("time", "");
    }
  });

  if (availableSlots == null) {
    return <div>Loading...</div>;
  }

  const tutorName = tutor?.display_name ?? tutor?.first_name ?? "Tutor";
  const contentAboveTitle = tutor ? (
    <div className={css.tutorInfo}>
      <ProfilePhoto alt={tutor.display_name ?? "tutor profile photo"} src={tutor.photo_url} size={30} />
      <DDSLabelText level={1}>{`${tutorName}'s schedule`}</DDSLabelText>
    </div>
  ) : null;

  return (
    <SingleClassForm
      disabled={disabled}
      onSubmit={onSubmit}
      title={title}
      onBack={onBack}
      contentAboveTitle={contentAboveTitle}
      contentMaxWidth={342}
      useMobileHeader
    >
      <div className={css.wrapper}>
        <TimeZoneDropdown />
        <SelectDate form={form} availableDays={availableDays} forceCalendarToCurrentWeek force24HoursMinimum />
        {selectedDate != null && (
          <>
            <HorizontalLine />
            <div className={css.timeSelectionSection}>
              <SelectedDate date={selectedDate} />
              <ClassLogisticsLine />
              <SelectTime form={form} availableDays={availableDays} timezone={timezone} showDateAndTimeZone={false} />
            </div>
          </>
        )}
      </div>
    </SingleClassForm>
  );
};

export default CalendarAndTimesForTutor;
