import { HTMLAttributes, ReactNode, useCallback, useContext } from "react";
import { RadioGroupContext } from "./index";
import css from "./RadioGroupOption.module.css";

type Props<TValue> = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  children: ReactNode;
  disabled?: boolean;
  value: TValue;
  name?: string;
  className?: string;
};
// eslint-disable-next-line comma-spacing
const RadioGroupOption = <TValue,>({ children, disabled = false, value, name, ...props }: Props<TValue>) => {
  const context = useContext(RadioGroupContext);
  const onClick = useCallback(() => {
    context.onChange(value);
  }, [context, value]);
  const checked = context.value === value;

  return (
    <div {...props}>
      <label className={css.optionLabel} data-disabled={disabled} data-checked={value === context.value}>
        <input
          className={css.optionInput}
          type="radio"
          name={name || `radio-group-option-${context.id}`}
          onChange={disabled || checked ? undefined : onClick}
          defaultChecked={value === context.value}
          disabled={disabled}
          tabIndex={0}
        />
        <div>{children}</div>
      </label>
    </div>
  );
};

export default RadioGroupOption;
