type FeatureSwitchName = `web_marketplace_${string}` | `api_marketplace_${string}`;

type ValidValues = "test" | "variant1" | "variant2" | "variant3" | "holdout";

type LoggedOutSwitchMap = Record<
  FeatureSwitchName,
  {
    entityType: "parent" | "teacher";
    values: readonly ["off", "control", ValidValues, ...ValidValues[]] | readonly ["off", "on"];
  }
>;

type LoggedInSwitchMap = Record<
  FeatureSwitchName,
  {
    values: readonly ["off", "control", ValidValues, ...ValidValues[]] | readonly ["off", "on"];
  }
>;

export const LOGGED_OUT_SWITCH_MAP = {
  web_marketplace_trial_parent_agency_holdout: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_date_cleanup_v2_nov2024: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_algolia_search_parent_value_v4: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_algolia_search_tutor_value_v4: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_show_featured_reviews: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_account_creation_value_v5_mar2025: {
    entityType: "parent",
    values: ["off", "control", "variant1", "variant2", "variant3"],
  },
  web_marketplace_trial_pricing_feb2025: {
    entityType: "parent",
    values: ["off", "control", "holdout", "test"],
  },
  web_marketplace_inverted_funnel_mar2025: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_inverted_funnel_apr2025: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_licensed_tutor_pricing: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_auto_select_day_trial_apr2025: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
} as const satisfies LoggedOutSwitchMap;

export const LOGGED_IN_SWITCH_MAP = {
  web_marketplace_hide_pause_subscription_option: {
    values: ["off", "control", "test"],
  },
  web_marketplace_book_more_classes_dec2024: {
    values: ["off", "control", "test"],
  },
  web_marketplace_generated_lesson_plan_activities: {
    values: ["off", "control", "test"],
  },
  web_marketplace_manage_classes_feb2025_v2: {
    values: ["off", "control", "test"],
  },
  web_marketplace_video_highlight_generation: {
    values: ["off", "control", "test"],
  },
  web_marketplace_zoom_bot: {
    values: ["off", "control", "variant1", "variant2"],
  },
  web_marketplace_premium_subscription_pricing: {
    values: ["off", "control", "test"],
  },
  web_marketplace_post_trial_booking_notes_mar2025: {
    values: ["off", "control", "test"],
  },
} as const satisfies LoggedInSwitchMap;
