import { useGlobalStore } from "#/old/src/store";
import useScheduleSlotsQuery from "#/src/hooks/api/tutor/useScheduleSlotsQuery";
import { useMemo, useEffect } from "react";
import { usePrevious } from "@classdojo/web";
import { addHours, addMinutes, endOf, formatWithPattern, parse, isSame, startOf, addDays } from "#/src/utils/dates";
import { logTutorEvent } from "#/src/utils/log";

export const useLogEventForAvailableSlots = (
  date: Date | null,
  timezone: string,
  availableSlots: string[] | undefined,
) => {
  const [state] = useGlobalStore();

  const previousDate = usePrevious(date);

  const availableDays = useMemo(() => {
    if (!availableSlots || !timezone) return [];

    const startingDay = addDays(startOf(new Date(), "day", timezone), 1);
    const availableSlotsTimes = availableSlots ? availableSlots.map((slot) => parse(slot)) : [];

    return Array.from({ length: 7 }, (_v, index) => {
      const day = addDays(startingDay, index);
      const nextDay = addDays(startingDay, index + 1);

      const slots = availableSlotsTimes
        .filter((slot) => slot >= day && slot < nextDay)
        .map((slot) => formatWithPattern(parse(slot), "hh:mm a", { timeZone: timezone }));

      return { day: formatWithPattern(day, "PP", { timeZone: timezone }), slots };
    });
  }, [availableSlots, timezone]);

  const selectedDaySlots = useMemo(() => {
    if (date == null) return [];

    const dayData = availableDays.find((day) => day.day === formatWithPattern(date, "PP", { timeZone: timezone }));
    return dayData ? dayData.slots : [];
  }, [availableDays, date, timezone]);

  useEffect(() => {
    if (date != null && date?.toISOString() !== previousDate?.toISOString() && availableSlots != null) {
      const twentyFourHoursFromNow = addMinutes(addHours(startOf(new Date(), "hour", timezone), 24), 30);
      let currentSlot = date < twentyFourHoursFromNow ? twentyFourHoursFromNow : date;
      const endingPoint = endOf(currentSlot, "day", timezone);
      const slotAvailability: Record<string, boolean> = {};

      while (currentSlot < endingPoint) {
        const twelveHourTime = formatWithPattern(currentSlot, "hh:mm a");
        const twentyFourHourTime = formatWithPattern(currentSlot, "HH:mm");
        slotAvailability[twentyFourHourTime] = selectedDaySlots.includes(twelveHourTime);
        currentSlot = addMinutes(currentSlot, 30);
      }
      const payload = {
        email: state.booking?.email,
        subject: state.booking?.subject,
        grade: state.booking?.grade,
        timezone,
        date,
        slotAvailability,
        booking: state.booking,
      };

      logTutorEvent({
        eventName: "web.marketplace.parent.tutor_trial_checkout.date_time.available_slots",
        metadata: payload,
      });
    }
  }, [date, previousDate, selectedDaySlots, timezone, state.booking, availableSlots]);
};

function formatTime(time: Date, timezone: string) {
  return formatWithPattern(time, "h:mm a", { timeZone: timezone });
}

export default function useAvailableSpotsByDate(date: Date, timezone: string) {
  const [state] = useGlobalStore();
  const { availableSlots } = useScheduleSlotsQuery(
    state.booking && {
      subject: state.booking.subject,
      grade: state.booking.grade,
    },
  );

  useLogEventForAvailableSlots(date, timezone, availableSlots);

  const availableTimes = useMemo(
    () =>
      availableSlots
        ? availableSlots
            .map((slot) => parse(slot))
            .filter((slot) => {
              return isSame(slot, date, { mode: "day" });
            })
            .map((slot) => formatTime(slot, timezone))
        : [],
    [availableSlots, date, timezone],
  );
  return availableTimes;
}
