import { useLayoutEffect } from "react";
import { useLocation } from "react-router";

const useScrollToTopOnLoad = (scrollToTop: boolean = true) => {
  // Since many routes return this component, React doesn't see the re-render as a new instance.
  // We need to check the pathname to address route changes if we want this to run on each page load.
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop, pathname]);
};

export default useScrollToTopOnLoad;
