import { DDSBodyText, DDSButton } from "@web-monorepo/dds";
import css from "./index.module.css";
import cn from "#/src/utils/cn";
import { abbreviateDay, DAY_NAMES_MONDAY_FIRST } from "#/src/utils/dayOfWeek";

const DaysOfWeekButtonRow = ({
  onClick,
  currentSelections,
}: {
  onClick: (value: string) => void;
  currentSelections: string[];
}) => {
  return (
    <div>
      <DDSBodyText level={3}>Days</DDSBodyText>
      <div className={css.daysOfWeekButtonRow}>
        {DAY_NAMES_MONDAY_FIRST.map((value) => (
          <DDSButton
            variant="secondary"
            data-name={`tutor-selection-change-date-modal:select-day:${value}`}
            key={value}
            onClick={() => onClick(value)}
            className={cn([css.daysOfWeekButtonRowLabel, { [css.selected]: currentSelections.includes(value) }])}
          >
            {abbreviateDay(value)}
          </DDSButton>
        ))}
      </div>
    </div>
  );
};

export default DaysOfWeekButtonRow;
