import { components } from "@classdojo/ts-api-types/api";
import bookClass from "./bookClass";
import bookSubscription from "./bookSubscription";
import getClassById from "./getClassById";
import getSessionById from "./getSessionById";
import getClasses from "./getClasses";
import getNextCycleDate from "./getNextCycleDate";
import getStripePaymentMethods from "./getStripePaymentMethods";
import getStripeSecret from "./getStripeSecret";
import getStudentMeeting from "./getStudentMeeting";
import postCOPPAConsent from "./postCOPPAConsent";
import postCOPPAConsentFromToken from "./postCOPPAConsentFromToken";
import postSubscriptionStartDate from "./postSubscriptionStartDate";
import reviewClass from "./reviewClass";
import updateClass from "./updateClass";
import rescheduleSubscription from "src/api/parent/rescheduleSubscription";
import consentSubscriptionSharing from "src/api/parent/consentSubscriptionSharing";

export type Grade = components["schemas"]["TutorGrades"];
export type Subject = components["schemas"]["TutorSubjects"];
export type TutorProfileSubject = components["schemas"]["TutorProfileSubjects"];

export default {
  bookClass,
  bookSubscription,
  getClassById,
  getClasses,
  getNextCycleDate,
  getStripePaymentMethods,
  getStripeSecret,
  getStudentMeeting,
  postCOPPAConsent,
  postCOPPAConsentFromToken,
  postSubscriptionStartDate,
  rescheduleSubscription,
  reviewClass,
  updateClass,
  consentSubscriptionSharing,
  getSessionById,
};
