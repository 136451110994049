import useIsMobile from "#/src/hooks/useIsMobile";
import { theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useContext, useState } from "react";
import LoggedOutHeaderContents from "old/src/components/LoggedOutHeaderContents";
import MobileNav from "old/src/components/MobileNav";
import ParentHeaderContents from "old/src/components/ParentHeaderContents";
import PrivateHeaderContents from "old/src/components/PrivateHeaderContents";
import { HEADER_HEIGHT } from "src/constants";
import { useSessionQuery } from "src/hooks/useSession";
import StepperContext from "#/src/pages/single-class/_components/StepperContext";

const Header = () => {
  const [navbarOpened, setNavbarOpened] = useState(false);
  const { sessionData } = useSessionQuery();
  const isMobile = useIsMobile();
  const stepper = useContext(StepperContext);

  const role = sessionData?.roles?.includes("teacher")
    ? "teacher"
    : sessionData?.roles?.includes("parent")
      ? "parent"
      : "none";

  return (
    <>
      <div sx={sxHeaderWrapper}>
        <div sx={isMobile ? sxHeaderContentsMobile : sxHeaderContents}>
          {role === "teacher" && (
            <PrivateHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
          {role === "parent" && (
            <ParentHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
          {role === "none" && (
            <LoggedOutHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
        </div>
        {stepper.step != null && stepper.total != null && (
          <div
            sx={{
              width: "100%",
              backgroundColor: "rgba(110, 120, 180, 20%)",
            }}
          >
            <div
              sx={{
                height: 6,
                backgroundColor: "#7D40FF",
                width: `${(stepper.step * 100) / stepper.total}%`,
                transition: "width ease-out 300ms",
              }}
            />
          </div>
        )}
      </div>
      <MobileNav opened={navbarOpened} onClose={() => setNavbarOpened(false)} />
    </>
  );
};

export default Header;

const sxHeaderWrapper: ThemeUIStyleObject = {
  zIndex: 99,
  backgroundColor: "dt_background_primary",
  borderBottom: "dt_divider",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const sxHeaderContents: ThemeUIStyleObject = {
  height: HEADER_HEIGHT,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: theme.breakpoints.xl,
  position: "relative",
  padding: 0,
  paddingLeft: "dt_m",
  paddingRight: "dt_m",
};

const sxHeaderContentsMobile: ThemeUIStyleObject = {
  ...sxHeaderContents,
  justifyContent: "flex-start",
};
