import { DDSBodyText, DDSButton } from "@web-monorepo/dds";
import css from "./index.module.css";
import cn from "#/src/utils/cn";
import { TIME_RANGE_OPTIONS, type TimeRange } from "#/src/utils/timeRange";

const TimeRangesButtonRow = ({
  onClick,
  currentSelections,
}: {
  onClick: (value: TimeRange["name"]) => void;
  currentSelections: TimeRange["name"][];
}) => {
  return (
    <div>
      <DDSBodyText level={3}>Times</DDSBodyText>
      <div className={css.timeRangesButtonRow}>
        {TIME_RANGE_OPTIONS.map(({ name, label }) => (
          <DDSButton
            variant="secondary"
            data-name={`tutor-selection-change-date-modal:select-time-range:${label.toLowerCase().replace(" ", "-")}`}
            key={name}
            onClick={() => onClick(name)}
            className={cn([css.timeRangesButtonRowLabel, { [css.selected]: currentSelections.includes(name) }])}
          >
            <DDSBodyText className={css.timeRangeOptionName} level={3}>
              {name}
            </DDSBodyText>
            <DDSBodyText level={2}>{label}</DDSBodyText>
          </DDSButton>
        ))}
      </div>
    </div>
  );
};

export default TimeRangesButtonRow;
