/* eslint-disable @web-monorepo/no-nessie-typography */
/* eslint-disable @web-monorepo/no-sx-property */
/* eslint-disable @web-monorepo/automatic-translations */

import { UnstyledButton } from "@classdojo/web";
import { BodyText, theme } from "@classdojo/web/nessie";
import { IconCalendarDelete, IconPlus, IconTrash } from "@web-monorepo/dds-icons";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { showNotification } from "@mantine/notifications";
import capitalize from "lodash/capitalize";
import { ActionIcon, Check, BaseCheckbox as Checkbox, RawSelect, Text, TimezoneText } from "UILibrary";
import { DDSButton } from "@web-monorepo/dds";
import cloneDeep from "lodash/cloneDeep";
import { useAvailabilityQuery, useTeacherMutation, useTutorSubscriptionConflictsQuery } from "old/src/hooks/useTeacher";
import useTimeSelectIntervals from "src/hooks/useTimeSelectIntervals";
import ConflictConfirmationModal from "old/src/pageComponents/Teachers/Availability/ConflictConfirmationModal";
import DayConflictsModal from "old/src/pageComponents/Teachers/Availability/DayConflictsModal";
import { useEffect, useMemo, useState } from "react";
import queryClient from "src/queryClient";
import { DAYS } from "src/settings/availability/constants";
import { type DAY_KEYS, DAYS_NAMES } from "#/src/utils/dayOfWeek";
import { DAY_VALUE } from "src/settings/availability/constants";
import { logTutorEvent } from "src/utils/log";
import { isBefore, parseWithPattern, getDefaultTimezone } from "src/utils/dates";
import OutOfOfficeButton from "./OutOfOfficeButton";
import { keys } from "@classdojo/web/utils/objects";

type DayModal = {
  day: DAY_KEYS;
  conflicts: Array<{
    name: string;
    conflicts: string[];
  }>;
};

// eslint-disable-next-line complexity
const Availability = () => {
  const [days, setDays] = useState<Record<DAY_KEYS, DAY_VALUE> | null>(null);
  const times = useTimeSelectIntervals();
  const { updateAvailability } = useTeacherMutation();
  const { availability, isLoading } = useAvailabilityQuery();
  const [dayModal, setDayModal] = useState<DayModal | null>(null);

  const updatedAvailability = useMemo(() => {
    if (days == null) return;

    const updated = cloneDeep(days);

    keys(updated).forEach((day) => {
      delete updated[day].maxClasses;
      if (updated[day].ranges.length === 1 && updated[day].ranges[0].length === 0) {
        updated[day].ranges = [];
        updated[day].isAvailable = false;
      } else {
        updated[day].ranges = updated[day].ranges.filter((range) => range.filter(Boolean).length === 2);
      }
    });

    return updated;
  }, [days]);

  const { conflicts, isLoading: isSubscriptionConflictsLoading } = useTutorSubscriptionConflictsQuery(
    updatedAvailability && {
      availability: updatedAvailability,
      timezone: getDefaultTimezone(),
    },
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (availability?.availability) {
      const data = (Object.keys(availability?.availability) as DAY_KEYS[])
        .sort((a, b) => DAYS_NAMES.indexOf(a) - DAYS_NAMES.indexOf(b))
        .reduce(
          (obj, key) => {
            const day = availability.availability[key];
            if (day == null) return obj;

            // there could be a case when day is not available and it doesn't have ranges
            // in this case we should use default ranges
            const ranges =
              day.ranges.length === 0 && !day.isAvailable
                ? DAYS[key].ranges
                : day.ranges.map((range) => {
                    if (range.length) {
                      const startHour = range[0];
                      const endHour = range[1];
                      return [startHour, endHour];
                    } else {
                      return [];
                    }
                  });

            obj[key] = { ...day, ranges };

            return obj;
          },
          {} as typeof DAYS,
        );

      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setDays(data);
    } else {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setDays(DAYS);
    }
  }, [availability, availability?.availability, availability?.timezone]);

  const onUpdateAvailability = async () => {
    if (updatedAvailability == null) return;
    for (const day in updatedAvailability) {
      for (const range of updatedAvailability[day as DAY_KEYS].ranges) {
        if (
          range.length &&
          isBefore(parseWithPattern(range[1], ["h:mm a"]), parseWithPattern(range[0], ["h:mm a"]), { inclusive: true })
        ) {
          showNotification({
            title: "Invalid time range selected",
            message: "Please verify all availability end times are after start times",
          });
          return;
        }
      }
    }

    const body = {
      availability: updatedAvailability,
      timezone: getDefaultTimezone(),
    };

    await updateAvailability.mutateAsync(body, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["availability"] });
        showNotification({
          title: "Availability updated",
          message: (
            <Text>
              We just updated your availability time.
              <br />
              Make sure to let your students know!
            </Text>
          ),
          color: "teal",
          icon: <Check />,
        });
        setShowConfirmationModal(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  const updateOrShowConfirmationModal = () => {
    if (conflicts == null || isSubscriptionConflictsLoading) return;

    if (Object.keys(conflicts).length > 0) setShowConfirmationModal(true);
    else onUpdateAvailability();
  };

  const onRemoveTimeList = (index: number, day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];

      newRanges.splice(index, 1);

      setDays({
        ...days,
        [day]: {
          ...days[day],
          ranges: newRanges,
        },
      });
    }
  };

  const onAddTimeList = (day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];
      newRanges.push([]);

      setDays({
        ...days,
        [day]: {
          ...days[day],
          ranges: newRanges,
        },
      });
    }
  };

  const getEndTimeOptions = (index: number, day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];

      const startTime = newRanges[index][0];

      if (startTime) {
        const startTimeIndex = times.findIndex((value: string) => startTime === value);
        return [...times].slice(startTimeIndex + 1);
      }
    }

    return times;
  };

  const renderTimeList = (ranges: string[][], day: DAY_KEYS) => {
    if (days) {
      return (
        <div sx={{ display: "flex", flexDirection: "column", gap: "dt_m" }}>
          {ranges.map((range: string[], index: number) => (
            <div
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "dt_m",
                ":last-child": {
                  marginBottom: 0,
                },
              }}
            >
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "102px auto 102px",
                  alignItems: "center",
                }}
              >
                <RawSelect
                  onChange={(value: string) => {
                    const newRanges = [...days[day].ranges];
                    if (
                      isBefore(parseWithPattern(newRanges[index][1], ["h:mm a"]), parseWithPattern(value, ["h:mm a"]), {
                        inclusive: true,
                      })
                    ) {
                      showNotification({
                        title: "Invalid time range selected",
                        message: "Please verify availability end time is after start time",
                      });
                      newRanges[index][1] = value;
                    }
                    newRanges[index] = [value, newRanges[index][1]];

                    setDays({
                      ...days,
                      [day]: { ...days[day], ranges: newRanges },
                    });
                  }}
                  value={range[0]}
                  data={times}
                  searchable
                />
                <Text mx={8}>-</Text>
                <RawSelect
                  onChange={(value: string) => {
                    const newRanges = [...days[day].ranges];

                    newRanges[index] = [newRanges[index][0], value];

                    setDays({
                      ...days,
                      [day]: { ...days[day], ranges: newRanges },
                    });
                  }}
                  value={range[1]}
                  data={getEndTimeOptions(index, day)}
                  searchable
                />
              </div>
              {ranges.length > 1 && (
                <ActionIcon
                  onClick={() =>
                    days[day].ranges.length <= 1
                      ? setDays({
                          ...days,
                          [day]: {
                            ...days[day],
                            ranges: [],
                            isAvailable: false,
                          },
                        })
                      : onRemoveTimeList(index, day)
                  }
                  size={56}
                  sx={{ backgroundColor: "dt_background_secondary", borderRadius: "dt_radius_s" }}
                >
                  <IconTrash size="m" sx={{ color: "dt_content_primary" }} />
                </ActionIcon>
              )}

              {index === ranges.length - 1 && (
                <ActionIcon
                  onClick={() => onAddTimeList(day)}
                  size={56}
                  sx={{
                    borderRadius: "dt_radius_s",
                    color: "dt_content_accent",
                    fontWeight: 700,
                    minWidth: "110px",
                    [`@media (max-width: ${theme.breakpoints.m})`]: {
                      display: "none",
                    },
                  }}
                >
                  + Time Range
                </ActionIcon>
              )}
            </div>
          ))}
          <ActionIcon
            onClick={() => onAddTimeList(day)}
            size={56}
            sx={{
              backgroundColor: "dt_background_secondary",
              borderRadius: "dt_radius_s",
              display: "none",
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                display: "inherit",
              },
            }}
          >
            <IconPlus size="s" />
          </ActionIcon>
        </div>
      );
    }
  };

  const SaveAvailabilityButton = () => {
    return (
      <DDSButton
        data-name="save-availability"
        onClick={() => {
          logTutorEvent("tutor.marketplace.edit_availability.click_on_save");
          updateOrShowConfirmationModal();
        }}
        disabled={updateAvailability.isLoading || isSubscriptionConflictsLoading}
      >
        Save
      </DDSButton>
    );
  };
  const renderDaysList = () => {
    if (days) {
      return (Object.keys(days) as DAY_KEYS[])?.map((day: DAY_KEYS) => (
        <div
          key={day}
          sx={{
            paddingTop: "dt_m",
            paddingBottom: "dt_m",
            paddingLeft: "dt_m",
            borderRadius: "dt_radius_s",
            display: "flex",
            width: "100%",
            marginTop: "dt_s",
            marginBottom: "dt_s",
            borderBottom: "none",
            border: "dt_card",
            justifyContent: "space-between",
            borderColor: "#e5e6ef",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                alignItems: "flex-start",
                flexDirection: "column",
              },
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                width: 104,
                height: 64,
                alignSelf: "flex-start",
                marginRight: 70,
              }}
            >
              <Checkbox
                sx={{
                  marginRight: "dt_l",
                  [`@media (max-width: ${theme.breakpoints.m})`]: {
                    marginRight: "dt_s",
                  },
                }}
                size="lg"
                checked={days[day].isAvailable}
                onChange={(event) =>
                  setDays({
                    ...days,
                    [day]: {
                      ...days[day],
                      isAvailable: event.currentTarget.checked,
                    },
                  })
                }
              />
              <Text
                sx={{
                  color: "dt_content_primary",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 700,
                }}
              >
                {capitalize(day)}
              </Text>
            </div>

            <div sx={sxTimeListWrapper}>
              {days[day].isAvailable ? (
                <div
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {renderTimeList(days[day].ranges, day)}
                </div>
              ) : (
                <Text
                  sx={{
                    color: "dt_content_secondary",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 600,
                  }}
                >
                  Unavailable
                </Text>
              )}
              {conflicts?.[day] && (
                <BodyText level={2}>
                  {conflicts?.[day]?.length} {conflicts?.[day]?.length === 1 ? "subscriber" : "subscribers"} affected{" "}
                  <UnstyledButton
                    data-name=""
                    type="button"
                    sx={{ color: "dt_content_accent", cursor: "pointer" }}
                    onClick={() => {
                      logTutorEvent("tutor.marketplace.edit_availability.click_on_affected_subscribers");
                      setDayModal({
                        day,
                        conflicts:
                          conflicts?.[day]?.map((conflict) => ({
                            name: conflict.studentName,
                            conflicts: conflict.conflicts,
                          })) ?? [],
                      });
                    }}
                  >
                    View
                  </UnstyledButton>
                </BodyText>
              )}
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "dt_l",
          flexWrap: "wrap",
          gap: "dt_m",
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Text
          sx={{
            fontSize: "28px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "dt_content_primary",
          }}
        >
          Set your availability for classes
        </Text>
        <div
          sx={{
            display: "flex",
            gap: "dt_m",
            flex: 1,
            minWidth: "240px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <OutOfOfficeButton />
          <SaveAvailabilityButton />
        </div>
      </div>
      <div
        sx={{
          border: "none",
          boxShadow: "none",
          borderRadius: "dt_radius_m",
          height: "100%",
          marginBottom: RAW_cssValue("64px"),
          margin: "auto",
          maxWidth: "800px",
        }}
      >
        <div
          sx={{
            display: "flex",
            gap: "dt_xs",
            padding: "dt_m",
            borderRadius: "dt_radius_xs",
            backgroundColor: "dt_background_accent",
            flexWrap: "wrap",
          }}
        >
          <IconCalendarDelete size="m" />
          <Text>
            <strong>Looking to take some time off?</strong>
          </Text>
          <Text>Add some out-of-office time instead.</Text>
          <Text>
            <a
              sx={{ textDecoration: "none", fontWeight: 700, color: "dt_content_accent" }}
              target="_blank"
              href="https://tutor-help.classdojo.com/hc/en-us/articles/31637318756493-For-Tutors-Out-Of-Office-Hours-FAQs"
            >
              Learn how
            </a>
          </Text>
        </div>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: "dt_l",
              paddingBottom: "0",
              paddingLeft: "dt_xl",
              paddingRight: "dt_xl",
              borderBottom: "none",
              width: "100%",
              justifyContent: "space-between",

              [`@media screen and (max-width: ${theme.breakpoints.m})`]: {
                flexDirection: "column",
                padding: "dt_s",
              },
            }}
          >
            <div sx={{ display: "flex", alignItems: "center" }}>
              <Text sx={{ fontSize: "18px", lineHeight: "28px" }}>Class duration:</Text>
              <Text
                sx={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "dt_content_secondary",
                  paddingLeft: "dt_m",
                  paddingRight: "dt_m",
                }}
              >
                25 min
              </Text>
            </div>
            <div sx={{ display: "flex", alignItems: "center" }}>
              <Text sx={{ fontSize: "18px", lineHeight: "28px" }}>Timezone:</Text>
              <TimezoneText
                sx={{
                  marginLeft: "8px",
                  color: "dt_content_secondary",
                  fontSize: "18px",
                  lineHeight: "28px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            [`@media(max-width: ${theme.breakpoints.xl})`]: {
              display: "block",
            },
          }}
        >
          <div
            sx={{
              alignItems: "center",
              padding: "dt_l",
              paddingLeft: "dt_xl",
              paddingRight: "dt_xl",
              width: "100%",
              borderRight: "none",

              [`@media screen and (max-width: ${theme.breakpoints.m})`]: {
                padding: "dt_s",
              },
            }}
          >
            {!isLoading && <div>{renderDaysList()}</div>}
            <div sx={{ display: "flex", justifyContent: "center", paddingTop: "dt_l" }}>
              <SaveAvailabilityButton />
            </div>
          </div>
        </div>
      </div>
      {dayModal != null && (
        <DayConflictsModal day={dayModal.day} conflicts={dayModal.conflicts} onClose={() => setDayModal(null)} />
      )}
      {showConfirmationModal && conflicts != null && (
        <ConflictConfirmationModal
          conflicts={conflicts}
          onClose={() => setShowConfirmationModal(false)}
          onSave={onUpdateAvailability}
        />
      )}
    </div>
  );
};

export default Availability;

const sxTimeListWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_s",
};
