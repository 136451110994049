import { useCallback } from "react";
import { useModalStore } from "#/src/components/Modals";
import ModalWrapper from "#/src/components/Modals/ModalWrapper";
import { DDSBodyText, DDSButton } from "@web-monorepo/dds";
import css from "./index.module.css";
import { zodResolver } from "@hookform/resolvers/zod";
import useIsMobile from "#/src/hooks/useIsMobile";
import { UnstyledButton } from "@classdojo/web";
import SelectDate from "#/src/pageComponents/RescheduleSession/SelectDate";
import { useForm } from "react-hook-form";
import { z } from "zod";
import SelectTime from "#/src/pageComponents/RescheduleSession/SelectTime";
import {
  ClassLogisticsLine,
  SelectedDate,
  useAvailableSlotsGroupedByDate,
  useBookingTimezone,
} from "#/src/pages/single-class/_components/CalendarAndTimesForTutor";
import {
  addDays,
  addHours,
  startOf,
  generateValidSlotsInRange,
  parseWithPattern,
  formatWithPattern,
  parseIsoStringInTimeZone,
} from "#/src/utils/dates";
import HorizontalLine from "#/src/components/HorizontalLine";
import { useGlobalStore } from "#/old/src/store";
import { logTutorEvent } from "#/src/utils/log";

const FormSchema = z.object({
  date: z.string(),
  time: z.string(),
});
type FormData = Partial<z.infer<typeof FormSchema>>;

const SelectExactDate = ({ onModeChange, onSave }: { onModeChange: () => void; onSave: (payload: any) => void }) => {
  const [state] = useGlobalStore();
  const isMobile = useIsMobile();
  const modalVariant = isMobile ? "bottomDrawer" : "centered";
  const timezone = useBookingTimezone();

  const startofNextHour = startOf(addHours(new Date(), 1), "hour");
  const availableSlots = generateValidSlotsInRange({
    startDate: addDays(startofNextHour, 1),
    endDate: addDays(startofNextHour, 8),
  });

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: { date: state.booking?.date ?? "", time: state.booking?.time ?? "" },
  });

  const slotsByDate = useAvailableSlotsGroupedByDate(availableSlots);
  const availableDays = Object.values(slotsByDate);

  const selectedDateString = form.watch("date");
  const selectedDate =
    selectedDateString === "" ? null : parseWithPattern(selectedDateString, "MMMM d, yyyy", undefined, timezone);

  const hideModal = useModalStore((state) => state.hideModal);

  const date = form.watch("date");
  const time = form.watch("time");
  const onClick = useCallback(() => {
    const parsedTime = time ? parseIsoStringInTimeZone(time, timezone) : null;
    const formattedTime = parsedTime ? formatWithPattern(parsedTime, "hh:mm a") : null;

    if (date && formattedTime) {
      onSave({ date, time: formattedTime, dateObj: parsedTime });
    }
    hideModal();
  }, [onSave, hideModal, timezone, date, time]);

  const submitDisabled = date === "" || time === "";

  return (
    <ModalWrapper
      variant={modalVariant}
      title="Choose a specific date and time"
      maxWidth="440px"
      onClose={() => {
        logTutorEvent({
          eventName:
            "web.marketplace.parent.tutor_trial_checkout.tutor_selection_change_date_modal.select_exact_date.close.click",
        });
        hideModal();
      }}
    >
      <div className={css.formWrapper}>
        <UnstyledButton
          className={css.modeSwitch}
          data-name="tutor-selection-change-date-modal:select-time-window"
          onClick={onModeChange}
        >
          <DDSBodyText level={2}>Choose a time window</DDSBodyText>
        </UnstyledButton>

        <SelectDate form={form} availableDays={availableDays} />

        <HorizontalLine />
        <div className={css.timeSelectionSection}>
          {selectedDate == null ? (
            <div>First, select a day above</div>
          ) : (
            <>
              <SelectedDate date={selectedDate} />
              <ClassLogisticsLine />
              <div className={css.timeSlotList}>
                <SelectTime form={form} availableDays={availableDays} timezone={timezone} showDateAndTimeZone={false} />
              </div>
            </>
          )}
        </div>

        <DDSButton
          disabled={submitDisabled}
          data-name="tutor-selection-change-date-modal:exact-date:view-results"
          onClick={() => {
            logTutorEvent({
              eventName:
                "web.marketplace.parent.tutor_trial_checkout.tutor_selection_change_date_modal.select_exact_date.view_results.click",
              metadata: {
                date: selectedDateString,
                time,
              },
            });
            onClick();
          }}
        >
          View results
        </DDSButton>
      </div>
    </ModalWrapper>
  );
};

export default SelectExactDate;
