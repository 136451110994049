import useBoolean from "#/src/hooks/useBoolean";
import SelectExactDate from "./SelectExactDate";
import SelectDaysAndTimeRanges from "./SelectDaysAndTimeRanges";
import { useGlobalStore } from "#/old/src/store";

const TutorSelectionChangeDateModal = ({ handlePayload }: { handlePayload: (payload: any) => void }) => {
  const [state] = useGlobalStore();
  const {
    isTrue: selectingExactDate,
    setTrue: setSelectingExactDate,
    setFalse: setSelectingDaysAndTimeRanges,
  } = useBoolean(state.booking?.date != null && state.booking?.time != null);

  if (selectingExactDate) {
    return <SelectExactDate onModeChange={setSelectingDaysAndTimeRanges} onSave={handlePayload} />;
  }

  return <SelectDaysAndTimeRanges onModeChange={setSelectingExactDate} onSave={handlePayload} />;
};

export default TutorSelectionChangeDateModal;
