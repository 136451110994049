import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, BodyText, HeadlineText, theme, TextArea } from "@classdojo/web/nessie";
import RadioGroup from "src/components/RadioGroup";
import RadioGroupOption from "src/components/RadioGroup/RadioGroupOption";
import { useState } from "react";
// eslint-disable-next-line @web-monorepo/no-nessie-icons
import { StarIcon } from "@classdojo/web/nessie/icons";
import { useGeneratedAssessmentReviewMutation } from "old/src/hooks/useTeacher";
import { GeneratedAssessmentReviewPayload } from "src/api/teacher/createGeneratedAssessmentReview";

const GeneratedAssessmentReview = () => {
  const query = useParams();
  const [searchParams] = useSearchParams();
  const generatedAssessmentId = query.generatedAssessmentId as string;
  const redirectTo = searchParams.get("redirectTo") ?? "/teachers/calendar";
  const [usedByTutor, setUsedByTutor] = useState<string | undefined>(undefined);
  const [difficultyToFill, setDifficultyToFill] = useState<string | undefined>(undefined);
  const [qualityRating, setQualityRating] = useState<number>(0);
  const [tutorComment, setTutorComment] = useState<string | undefined>(undefined);
  const generatedAssessmentReview = useGeneratedAssessmentReviewMutation();
  const push = useNavigate();

  const submit = () => {
    let payload: GeneratedAssessmentReviewPayload;
    if (usedByTutor === "yes") {
      payload = {
        usedByTutor: true,
        difficultyToFill: difficultyToFill as string,
        qualityRating,
        tutorComment: tutorComment || undefined,
      };
    } else {
      payload = {
        usedByTutor: false,
        tutorComment: tutorComment || undefined,
      };
    }

    generatedAssessmentReview.mutate(
      {
        generatedAssessmentId,
        ...payload,
      },
      {
        onSuccess: () => {
          push(redirectTo);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
      } as any,
    );
  };

  const skip = () => {
    push("/teachers/calendar");
  };

  const disabled =
    !usedByTutor ||
    (usedByTutor === "yes" && (!difficultyToFill || qualityRating < 1)) ||
    generatedAssessmentReview.isLoading;

  return (
    <div sx={sxWrapper}>
      <HeadlineText as="h2" sx={sxHeadline}>
        How was the AI-generated content?
      </HeadlineText>
      <div>
        <BodyText sx={sxLabel}> Did you use AI generated content for this assessment? </BodyText>
        <RadioGroup
          sx={sxRadioGroupUsed}
          onChange={(value) => {
            setUsedByTutor(value);
          }}
          value={usedByTutor}
        >
          <div sx={sxRadioGroupOptionUsed}>
            <RadioGroupOption value={"yes"}>Yes</RadioGroupOption>
          </div>
          <div sx={sxRadioGroupOptionUsed}>
            <RadioGroupOption value={"no"}>No</RadioGroupOption>
          </div>
        </RadioGroup>
      </div>
      {(!usedByTutor || usedByTutor === "yes") && (
        <>
          <div>
            <BodyText sx={sxLabel}> How long did it take to fill the assessment using AI generated content? </BodyText>
            <RadioGroup
              sx={sxRadioGroupTimeToFill}
              onChange={(value) => {
                setDifficultyToFill(value);
              }}
              value={difficultyToFill}
            >
              <div sx={sxRadioGroupOptionTimeToFill}>
                <RadioGroupOption value={"longer"}>Longer than usual</RadioGroupOption>
              </div>
              <div sx={sxRadioGroupOptionTimeToFill}>
                <RadioGroupOption value={"same"}>Same than usual</RadioGroupOption>
              </div>
              <div sx={sxRadioGroupOptionTimeToFill}>
                <RadioGroupOption value={"faster"}>Faster than usual</RadioGroupOption>
              </div>
            </RadioGroup>
          </div>
          <div>
            <BodyText sx={sxLabel}> How was the quality of the content generated with AI? </BodyText>
            <div sx={sxStarInput}>
              {Array.from({ length: 5 }, (_, starRate) => (
                <div
                  key={starRate + 1}
                  sx={sxStarWrapper}
                  onClick={() => {
                    setQualityRating(starRate + 1);
                  }}
                  // eslint-disable-next-line @web-monorepo/no-jsx-role-button
                  role="button"
                  tabIndex={0}
                >
                  <StarIcon sx={qualityRating >= starRate + 1 ? sxStarSelected : sxStar} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div>
        <TextArea
          resize="none"
          label="Comments"
          placeholder="Optional"
          minHeight={78}
          value={tutorComment}
          onChange={(value) => setTutorComment(value)}
        />
      </div>
      <div>
        <Button
          data-name="generatedAssessment:submit"
          kind="primary"
          sx={sxButton}
          disabled={disabled}
          onClick={submit}
        >
          Submit
        </Button>
        <Button data-name="generatedAssessment:skip" kind="tertiary" sx={sxButton} onClick={skip}>
          Skip
        </Button>
      </div>
    </div>
  );
};

export default GeneratedAssessmentReview;

const sxHeadline: ThemeUIStyleObject = {
  fontSize: "28px",
  fontWeight: 800,
  lineHeight: "36px",
  letterSpacing: "-0.2px",
};

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_l",
  minWidth: "320px",
  maxWidth: "600px",
  margin: "auto",
  marginTop: RAW_cssValue("-24px"),

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: RAW_cssValue("-36px"),
  },
};

const sxRadioGroupUsed: ThemeUIStyleObject = {
  display: "flex",
  gap: "dt_m",
  width: "100%",
  maxWidth: "738px",
};

const sxRadioGroupOptionUsed: ThemeUIStyleObject = {
  width: "161px",
};

const sxRadioGroupTimeToFill: ThemeUIStyleObject = {
  display: "flex",
  gap: "dt_s",
  width: "100%",
  flexDirection: "column",
};

const sxRadioGroupOptionTimeToFill: ThemeUIStyleObject = {
  width: "230px",
};

const sxLabel: ThemeUIStyleObject = {
  fontWeight: 700,
  marginBottom: "dt_xs",
};

const sxStarInput: ThemeUIStyleObject = {
  display: "flex",
};

const sxStar: ThemeUIStyleObject = {
  color: "dt_content_light",
  stroke: "dt_border_stylistic",
  fontSize: "38px",
};

const sxStarWrapper: ThemeUIStyleObject = {
  cursor: "pointer",
  width: "59px",
  height: "59px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const sxStarSelected: ThemeUIStyleObject = {
  ...sxStar,
  color: RAW_cssValue("#FFEA00"),
  stroke: RAW_cssValue("#FFEA00"),
  fontSize: "38px",
};

const sxButton: ThemeUIStyleObject = {
  width: "100%",
  marginBottom: "dt_s",
  height: "48px",
};
