import { useModalStore } from "#/src/components/Modals";
import ModalWrapper from "#/src/components/Modals/ModalWrapper";
import { DDSBodyText, DDSButton, DDSLabelText } from "@web-monorepo/dds";
import HorizontalLine from "#/src/components/HorizontalLine";

const ClassDiscountExclusionModal = () => {
  const hideModal = useModalStore((state) => state.hideModal);
  return (
    <ModalWrapper variant="centered" hideCloseButton>
      <div sx={{ display: "flex", flexDirection: "column", gap: "dt_l" }}>
        <DDSLabelText level={1}>Exclusions on class discounts</DDSLabelText>

        <HorizontalLine />

        <DDSBodyText level={3}>
          All the class times you select must be marked with a Save 23% label for the discount to apply to your weekly
          bill. If one or more classes aren’t marked with a Save 23% label, the discount will not apply and you will be
          charged the regular price for all weekly classes.
        </DDSBodyText>

        <DDSBodyText level={3}>To view discounted class times, tap on a day and “Choose time”.</DDSBodyText>

        <DDSButton data-name="hide-class-discount-exclusion-modal" onClick={hideModal}>
          Okay
        </DDSButton>
      </div>
    </ModalWrapper>
  );
};

export default ClassDiscountExclusionModal;
