import { useCallback, useRef } from "react";

export default function useRefCallback<TArgs extends any[], TReturn>(
  fn: (...args: TArgs) => TReturn,
): (...args: TArgs) => TReturn {
  const fnRef = useRef<(...args: TArgs) => TReturn>(fn);
  fnRef.current = fn;
  const wrappedFn = (...args: TArgs): TReturn => fnRef.current(...args);
  return useCallback(wrappedFn, []);
}
