import { formatWithPattern } from "#/src/utils/dates";

export const DAYS_NAMES = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] as const;
export const DAY_NAMES_MONDAY_FIRST = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export type DAY_KEYS = (typeof DAYS_NAMES)[number];

export const abbreviateDay = (day: DAY_KEYS) =>
  ({
    sunday: "Sun",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
  })[day];

export const getDayName = (date: Date): string => {
  return formatWithPattern(date, "EEEE").toLowerCase();
};
