import { monolithApiClient } from "#/src/configs/axios.config";

import { paths } from "@classdojo/ts-api-types";
import { useQuery } from "@tanstack/react-query";

type Endpoint = paths["/api/marketplace/checkout/price-map"]["get"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];
export type PriceMap = Response["priceMap"];

export default function useGetPriceMapQuery() {
  const { data, isLoading } = useQuery(["price-map"], () => monolithApiClient.get<Response>(`/checkout/price-map`));
  return {
    priceMap: data?.data.priceMap,
    isLoading,
  };
}
