import useRefCallback from "#/src/hooks/useRefCallback";
import { useEffect, useRef } from "react";

export default function useCallWhenReady<TArgs extends any[]>(isReady: boolean, ...args: TArgs) {
  const queue = useRef([] as Array<() => void>);

  // We use isReadyRef to make sure that `call` never updates.  This is because we will likely be using this hook in a
  // useEffect, and we don't want to trigger a re-render.
  const isReadyRef = useRef(isReady);
  isReadyRef.current = isReady;

  useEffect(() => {
    if (isReady) {
      queue.current.forEach((fn) => fn());
      queue.current = [];
    }
  }, [isReady]);

  return useRefCallback((fn: (...args: TArgs) => void) => {
    if (isReadyRef.current) fn(...args);
    else queue.current.push(() => fn(...args));
  });
}
