import { monolithApiClient } from "#/src/configs/axios.config";
import { useSessionQuery } from "#/src/hooks/useSession";
import { cacheDefaults } from "#/src/queryClient";
import { paths } from "@classdojo/ts-api-types";
import { useQuery } from "@tanstack/react-query";

type Endpoint = paths["/api/marketplace/schedule/slots/by-tutor"]["get"];
type Params = Endpoint["parameters"]["query"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

function getScheduleSlotsByTutor(params: Params) {
  return monolithApiClient.get<Response>("/schedule/slots/by-tutor", { params });
}

export default function useScheduleSlotsByTutorQuery(params?: Params) {
  const { sessionData } = useSessionQuery();
  const query = useQuery(
    ["availableSlotsByTutor", params],
    () => (params == null ? null : getScheduleSlotsByTutor(params)),
    {
      enabled:
        sessionData !== null &&
        params != null &&
        params.tutorId != null &&
        params.startDate != null &&
        params.endDate != null,
      refetchOnWindowFocus: false,
      ...cacheDefaults,
    },
  );

  return {
    ...query,
    availableSlots: query.data?.data?.available_slots,
  };
}
