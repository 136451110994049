/* eslint-disable @web-monorepo/marketplace-dates-imports -- this is the shim in question! */
import {
  parse,
  addHours,
  addMinutes,
  parseWithPattern as datesParseWithPattern,
  formatWithPattern as datesFormatWithPattern,
  isSame,
  startOf,
  subDays,
  overrideLocalTimezone,
  getLocalTimezoneOverride,
  enableGlobalTimezoneSupport,
  getTimezoneOffset,
} from "@web-monorepo/dates";

export * from "@web-monorepo/dates";

enableGlobalTimezoneSupport();

export function setDefaultTimezone(timezone: string | null) {
  overrideLocalTimezone(timezone);
}

export function getDefaultTimezone() {
  return getLocalTimezoneOverride() ?? getDeviceTimezone();
}

export function formatWithPattern(date: Date, pattern: string, options?: { timeZone?: string }): string {
  return datesFormatWithPattern(date, pattern === "PP" ? "MMMM dd, yyyy" : pattern, options);
}

export function formatDayOffRange(startTs: string, endTs?: string): string {
  const startMoment = parse(startTs);

  const startFormatted = formatWithPattern(startMoment, "MMMM d, yyyy");

  if (!endTs) return startFormatted;

  const endMoment = parse(endTs);
  let endFormatted;

  if (isSame(endMoment, startOf(endMoment, "day"))) {
    endFormatted = formatWithPattern(subDays(endMoment, 1), "MMMM d, yyyy");
  } else {
    endFormatted = formatWithPattern(endMoment, "MMMM d, yyyy");
  }

  return endFormatted !== startFormatted ? `${startFormatted} - ${endFormatted}` : startFormatted;
}

export const getHourWithDiffHour = (hour: string, tzDiffHours: number) => {
  /*
    Receive hour in format h:mm A, e.g.: 1:30 PM
    limited hours from 12:00 AM to 11:30 PM
  */
  return formatWithPattern(addHours(parseWithPattern(`${hour}`, ["h:mm a"]), tzDiffHours), "h:mm a");
};

function getDeviceTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getLocalizedDateTime = (date?: string, time?: string, timezone?: string) => {
  const timezoneToUse = timezone ?? getDefaultTimezone();

  const parsedDate = date
    ? datesParseWithPattern(date, ["PP", "MMMM dd yyyy", "MMMM dd, yyyy"], new Date(), timezoneToUse)
    : new Date();

  return time ? datesParseWithPattern(time, ["h:mm a", "HH:mm"], parsedDate, timezoneToUse) : parsedDate;
};

export const parseIsoStringInTimeZone = (isoString: string, timezone: string) => {
  return parseWithPattern(isoString, "yyyy-MM-dd'T'HH:mm:ss.SSSX", undefined, timezone);
};

export const parseWithPattern = (
  date: string | undefined,
  pattern: string | string[],
  referenceDate?: Date,
  timeZone?: string,
): Date => {
  // This is how moment behaves, but is not ideal:
  if (typeof date === "undefined") return new Date(NaN);

  // interop for a common pattern that is _close_:
  if (pattern === "PP") pattern = ["PP", "MMMM dd yyyy", "MMMM dd, yyyy"];

  return datesParseWithPattern(date, pattern, referenceDate, timeZone ?? getDefaultTimezone() ?? undefined);
};

export const getOffsetFromTimezone = (targetTimeZone: string) => {
  const userTimezone = getDefaultTimezone();
  const userOffset = getTimezoneOffset(userTimezone) / 60_000;
  const deviceOffset = getTimezoneOffset(targetTimeZone) / 60_000;
  return (userOffset - deviceOffset) / 60;
};

export function isDaylightSavings() {
  const now = new Date();
  const jan = new Date(now.getFullYear(), 0, 1); // January 1st
  const jul = new Date(now.getFullYear(), 6, 1); // July 1st (usually DST in effect if applicable)

  return now.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export const isDuringBusinessHours = (date: Date) => {
  const tzOffset = date.getTimezoneOffset() / 60;
  const hour = date.getHours();
  const tzOffsetEst = tzOffset - (isDaylightSavings() ? 4 : 5);
  const hourInEst = hour + tzOffsetEst;

  return hourInEst >= 8 && hourInEst <= 23;
};

export const generateValidSlotsInRange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  const availableSlots = [];
  let current = startDate;
  while (current < endDate) {
    if (isDuringBusinessHours(current)) {
      availableSlots.push(current.toISOString());
    }
    current = addMinutes(current, 30);
  }

  return availableSlots;
};
