import useIsMobile from "#/src/hooks/useIsMobile";
import useWatch from "@classdojo/web/hooks/useWatch";
import { BodyText, Button, HeadlineText, ProgressBar } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import Logo from "old/src/components/Logo";
import MobileNav from "old/src/components/MobileNav";
import { useGlobalStore } from "old/src/store";
import { FormEventHandler, ReactNode, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useHideHelperWidget from "src/hooks/useHideHelperWidget";
import { sxWrapperDesktop, sxWrapperMobile } from "src/pageComponents/NewSingleClass/SharedStyles";
import { IconArrowLeft, IconMenu } from "@web-monorepo/dds-icons";
import StepperContext from "src/pages/single-class/_components/StepperContext";
import css from "./SingleClassForm.module.css";

type SingleClassFormProps = {
  buttonText?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  error?: string;
  funnelGuard?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  showSubmitButton?: boolean;
  subtitle?: ReactNode;
  title?: ReactNode;
  contentMaxWidth?: number;
  onBack?: () => void;
  buttonSubText?: string;
  fixedSubmitButton?: boolean;
  aboveButtonContent?: ReactNode;
  replaceButtonContent?: ReactNode;
  sxTitle?: ThemeUIStyleObject;
  sxSubTitle?: ThemeUIStyleObject;
  progressBarPercentage?: number;
  noLeftBorderRadius?: boolean;
  useMobileHeader?: boolean;
  stretchSubmitedButton?: boolean;
  contentAboveTitle?: ReactNode;
};

// eslint-disable-next-line complexity
const SingleClassForm = ({
  children,
  disabled,
  error,
  onSubmit,
  title,
  subtitle,
  buttonText = "Continue",
  funnelGuard = false,
  showSubmitButton = true,
  contentMaxWidth,
  onBack,
  buttonSubText,
  fixedSubmitButton = true,
  aboveButtonContent,
  replaceButtonContent,
  sxTitle,
  progressBarPercentage,
  noLeftBorderRadius,
  useMobileHeader = false,
  stretchSubmitedButton = false,
  sxSubTitle,
  contentAboveTitle,
}: SingleClassFormProps) => {
  const push = useNavigate();
  const [state] = useGlobalStore();
  const [navbarOpened, setNavbarOpened] = useState(false);
  const stepperContext = useContext(StepperContext);
  const isMobile = useIsMobile();

  useHideHelperWidget();

  useWatch([funnelGuard, push, state?.booking?.uid, state?.checkoutFunnel?.paymentStatus], () => {
    if (funnelGuard && state?.checkoutFunnel?.paymentStatus === "completed") {
      const redirectionLink = state?.booking?.uid ? `/booking/${state?.booking?.uid}/success` : "/parents/classes";
      push(redirectionLink);
    }
  });

  const onSubmitWrapper: typeof onSubmit = useCallback(
    (e: Parameters<NonNullable<typeof onSubmit>>[0]) => {
      e.preventDefault();
      onSubmit?.(e);
    },
    [onSubmit],
  );

  return (
    <>
      {progressBarPercentage && (
        <div sx={{ position: "absolute", top: "65px", width: "100%" }}>
          <ProgressBar
            value={progressBarPercentage}
            backgroundBarColor="dt_background_accent"
            progressBarColor="dt_content_accent"
          />
        </div>
      )}
      {!isMobile && onBack && useMobileHeader && (
        <Button
          onClick={onBack}
          kind="tertiary"
          data-name="tutor-back"
          icon={<IconArrowLeft size="s" />}
          size="s"
          sx={{ position: "absolute", top: "110px", left: "10px", width: 55, height: 55 }}
        />
      )}
      <form
        sx={{
          ...(isMobile ? sxWrapperMobile : sxWrapperDesktop),
          ...(noLeftBorderRadius && !isMobile && sxNoLeftRadiusOverrides),
        }}
        onSubmit={onSubmitWrapper}
      >
        {isMobile && useMobileHeader && (
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 24,
            }}
          >
            {onBack ? (
              <Button
                onClick={onBack}
                kind="primary"
                data-name="tutor-back"
                icon={<IconArrowLeft size="s" />}
                size="s"
                sx={{
                  background: "transparent !important",
                  width: 45,
                  height: 45,
                  border: "1px solid rgba(0, 9, 91, 0.1)",
                }}
              />
            ) : (
              <div sx={{ width: 45, height: 45 }} />
            )}
            <Logo noText sx={{ width: "150px" }} />
            <Button
              onClick={() => setNavbarOpened(true)}
              kind="primary"
              data-name="tutor-hamburger"
              icon={<IconMenu size="s" />}
              size="s"
              sx={{
                background: "transparent !important",
                width: 45,
                height: 45,
                borderRadius: 12,
                padding: 8,
              }}
            />
          </div>
        )}
        {stepperContext.step != null && stepperContext.total != null && isMobile && (
          <div className={css.progressBar}>
            <div
              className={css.progressBarInner}
              sx={{ width: `${(stepperContext.step * 100) / stepperContext.total}%` }}
            />
          </div>
        )}
        {contentAboveTitle}
        {(title || subtitle) && (
          <div sx={{ textAlign: "center" }}>
            {title && (
              <HeadlineText as="h2" level={1} sx={{ ...sxTitle }}>
                {title}
              </HeadlineText>
            )}
            {subtitle && <BodyText sx={{ ...sxSubTitle }}>{subtitle}</BodyText>}
          </div>
        )}
        <div sx={{ ...sxContent, maxWidth: contentMaxWidth ? contentMaxWidth : "" }}>{children}</div>
        {(showSubmitButton || replaceButtonContent) && (
          <>
            {replaceButtonContent ? (
              <div
                sx={{
                  ...sxSubmitButtonWrapper,
                  ...(isMobile && Boolean(!buttonSubText && fixedSubmitButton) && sxSubmitButtonWrapperSticky),
                  padding: "0 !important",
                }}
              >
                {replaceButtonContent}
              </div>
            ) : (
              <div
                sx={{
                  ...sxSubmitButtonWrapper,
                  ...(isMobile && Boolean(!buttonSubText && fixedSubmitButton) && sxSubmitButtonWrapperSticky),
                }}
              >
                {error && (
                  <BodyText level={2} kind="danger">
                    {error}
                  </BodyText>
                )}
                {aboveButtonContent && <div>{aboveButtonContent}</div>}
                <Button
                  sx={{
                    ...(isMobile ? sxButtonMobile : sxButton),
                    ...(stretchSubmitedButton ? { maxWidth: "480px" } : undefined),
                  }}
                  type="submit"
                  disabled={disabled}
                  data-name="single_class_form:submit"
                >
                  {buttonText}
                </Button>
                {buttonSubText && (
                  <BodyText level={2} kind="tertiary">
                    {buttonSubText}
                  </BodyText>
                )}
              </div>
            )}
          </>
        )}
      </form>
      {useMobileHeader && <MobileNav opened={navbarOpened} onClose={() => setNavbarOpened(false)} />}
    </>
  );
};

export default SingleClassForm;

const sxContent: ThemeUIStyleObject = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginInline: "auto",
};

const sxButton: ThemeUIStyleObject = {
  minWidth: "fit-content",
  width: "calc(100% - 180px)",
};

const sxButtonMobile: ThemeUIStyleObject = {
  ...sxButton,
  width: "calc(100% - 24px)",
};

const sxSubmitButtonWrapper: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "dt_s",
};

const sxSubmitButtonWrapperSticky: ThemeUIStyleObject = {
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  borderTop: RAW_cssValue("4px solid #F1F3F8"),
  padding: "dt_s",
  backgroundColor: "dt_background_primary",
};

const sxNoLeftRadiusOverrides: ThemeUIStyleObject = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  marginTop: "dt_m",
  marginBottom: "dt_m",
};
