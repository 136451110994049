import useWatch from "@classdojo/web/hooks/useWatch";
import { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import Field from "src/components/form/Field";
import RadioGroup from "src/components/TDDS/TDDSRadioGroup";
import RadioGroupOption from "src/components/TDDS/TDDSRadioGroup/RadioGroupOption";
import { formatWithPattern, getDayOfWeek, parseWithPattern, getDefaultTimezone } from "src/utils/dates";
import { logTutorEvent } from "src/utils/log";
import css from "./SelectTime.module.css";
import cn from "src/utils/cn";

const SelectTime = ({
  form,
  availableDays,
  timezone,
  showDateAndTimeZone = true,
}: {
  form: UseFormReturn;
  availableDays: {
    day: string;
    slots: {
      classStartDate: string;
      startTimeLabel: string;
    }[];
  }[];
  timezone?: string;
  showDateAndTimeZone?: boolean;
}) => {
  const [showAllSlots, setShowAllSlots] = useState(false);
  const selectedDate = form.getValues().date;
  timezone = timezone ?? getDefaultTimezone();

  const selectedDaySlots = useMemo(() => {
    if (!availableDays) return [];

    return availableDays.find((day) => day.day === selectedDate)?.slots || [];
  }, [availableDays, selectedDate]);

  const filteredSlots = useMemo(() => {
    const weekday = getDayOfWeek(parseWithPattern(selectedDate, "PP"));

    let centralRange = ["03:00 PM", "07:00 PM"];
    if (weekday === 0 || weekday === 6) {
      centralRange = ["11:00 AM", "02:00 PM"];
    }
    let filteredSlots = selectedDaySlots.filter(
      (slot) =>
        parseWithPattern(slot.classStartDate, "hh:mm a") >= parseWithPattern(centralRange[0], "hh:mm a") &&
        parseWithPattern(slot.classStartDate, "hh:mm a") <= parseWithPattern(centralRange[1], "hh:mm a"),
    );

    if (filteredSlots.length < 8) {
      // special case, we don't have 8 values
      if (selectedDaySlots.length <= 8) {
        setShowAllSlots(true);
        return selectedDaySlots;
      }
      const targetLength = 8;
      const midIndex = Math.floor(selectedDaySlots.length / 2);
      const halfTarget = Math.floor(targetLength / 2);

      let startIndex = Math.max(0, midIndex - halfTarget);
      const endIndex = Math.min(selectedDaySlots.length, startIndex + targetLength);

      // Adjust startIndex if endIndex is at the array's end and we need more items
      if (endIndex === selectedDaySlots.length && endIndex - startIndex < targetLength) {
        startIndex = Math.max(0, endIndex - targetLength);
      }

      filteredSlots = selectedDaySlots.slice(startIndex, endIndex);
    }

    const selectedSlotIsFilteredOut =
      form.getValues().time &&
      selectedDaySlots.find((slot) => slot.classStartDate === form.getValues().time) &&
      !filteredSlots.find((slot) => slot.classStartDate === form.getValues().time);

    setShowAllSlots(filteredSlots.length === selectedDaySlots.length || selectedSlotIsFilteredOut);
    return filteredSlots;
  }, [selectedDaySlots, selectedDate, form]);

  useWatch(selectedDate, () => setShowAllSlots(false));

  if (!selectedDate) {
    return null;
  }

  return (
    <div className={css.container}>
      {showDateAndTimeZone && (
        <>
          <div className={css.divider} />
          <div className={css.dateHeader}>
            <div className={css.date}>{formatWithPattern(parseWithPattern(selectedDate, "PP"), "MMM d")}</div>
            <p>{timezone.replaceAll("_", " ")}</p>
          </div>
        </>
      )}
      <Field
        form={form}
        name="time"
        key={JSON.stringify(form.watch("time"))}
        render={({ value: time, onChange: onTimeChange }) => (
          <RadioGroup
            value={time}
            className={css.timeContainer}
            onChange={(newValue) => {
              logTutorEvent({
                eventName: "parent.marketplace.checkout_select_time",
                eventValue: newValue,
              });
              onTimeChange(newValue);
            }}
          >
            {(showAllSlots ? selectedDaySlots : filteredSlots).map((slot, index) => (
              <RadioGroupOption
                key={index}
                className={cn(css.timeItem, time === slot.classStartDate && css.selected)}
                value={slot.classStartDate}
                name={slot.startTimeLabel}
              >
                {slot.startTimeLabel}
              </RadioGroupOption>
            ))}
          </RadioGroup>
        )}
      />
      <div className={css.moreTimes}>
        {!showAllSlots && selectedDaySlots.length - filteredSlots.length ? (
          <div className={css.showMoreTimes} onClick={() => setShowAllSlots(true)} role="tab" tabIndex={0}>
            Show more times
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SelectTime;
