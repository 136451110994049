import { UseFormReturn } from "react-hook-form";
import Field from "src/components/form/Field";
import RadioGroup from "src/components/TDDS/TDDSRadioGroup";
import RadioGroupOption from "src/components/TDDS/TDDSRadioGroup/RadioGroupOption";
import cn from "src/utils/cn";
import {
  addDays,
  getDayOfWeek,
  isToday,
  parseWithPattern,
  subDays,
  startOf,
  formatWithPattern,
  isDuringBusinessHours,
  addHours,
} from "src/utils/dates";
import css from "./SelectDate.module.css";
import { zonedTimeToUtc, utcToZonedTime } from "src/utils/dates";

const SelectDate = ({
  form,
  availableDays,
  forceCalendarToCurrentWeek = false,
  force24HoursMinimum = false,
}: {
  form: UseFormReturn;
  availableDays: {
    day: string;
    slots: {
      classStartDate: string;
      startTimeLabel: string;
    }[];
  }[];
  forceCalendarToCurrentWeek?: boolean;
  force24HoursMinimum?: boolean;
}) => {
  const timeZone = "America/New_York"; // Or whatever timezone you're working with

  // Find the earliest and latest available days
  const sortedDays = availableDays
    .map((day) => zonedTimeToUtc(parseWithPattern(day.day, "PP"), timeZone))
    .sort((a, b) => a.getTime() - b.getTime());
  const earliestDay = sortedDays[0];

  // Find the first Sunday before or on the earliest available day
  const firstSunday = startOf(
    utcToZonedTime(subDays(earliestDay, getDayOfWeek(utcToZonedTime(earliestDay, timeZone))), timeZone),
    "day",
  );

  const startPoint = forceCalendarToCurrentWeek ? startOf(new Date(), "week", timeZone) : firstSunday;

  // Build array of 14 days, starting with the startPoint
  const days = Array.from({ length: 14 }, (_, index) => {
    const date = utcToZonedTime(addDays(zonedTimeToUtc(startPoint, timeZone), index), timeZone);
    const formattedDate = formatWithPattern(date, "PP", { timeZone });
    return {
      day: formattedDate,
      slots: availableDays.find((day) => day.day === formattedDate)?.slots ?? [],
      date,
    };
  });

  const twentyFourHoursFromNow = addHours(new Date(), 24);

  return (
    <Field
      form={form}
      key={JSON.stringify(form.watch("date"))}
      name="date"
      render={({ value, onChange: onDateChange }) => (
        <RadioGroup
          className={css.dayGrid}
          value={value}
          onChange={(value) => {
            onDateChange(value);
          }}
        >
          <div className={css.dayLabel}>SUN</div>
          <div className={css.dayLabel}>MON</div>
          <div className={css.dayLabel}>TUE</div>
          <div className={css.dayLabel}>WED</div>
          <div className={css.dayLabel}>THU</div>
          <div className={css.dayLabel}>FRI</div>
          <div className={css.dayLabel}>SAT</div>
          {days.map(({ day, slots, date }, index) => {
            const validSlots = slots.filter((slot) => {
              const slotDate = new Date(slot.classStartDate);
              return (!force24HoursMinimum || slotDate > twentyFourHoursFromNow) && isDuringBusinessHours(slotDate);
            });

            const isDisabled = validSlots.length === 0;

            return (
              <RadioGroupOption
                key={index}
                value={day}
                disabled={isDisabled}
                className={cn(
                  css.dayOption,
                  isDisabled && css.dayOptionDisabled,
                  isToday(date) && css.today,
                  day === value && css.selected,
                )}
                name={`day_${formatWithPattern(date, "dd", { timeZone })}`}
              >
                {formatWithPattern(date, "d", { timeZone })}
              </RadioGroupOption>
            );
          })}
        </RadioGroup>
      )}
    />
  );
};

export default SelectDate;
