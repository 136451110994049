/* eslint-disable @web-monorepo/no-sx-property */

import useIsMobile from "#/src/hooks/useIsMobile";
import React from "react";
// eslint-disable-next-line @web-monorepo/no-direct-react-router-imports
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import useStyles from "./styles/Header.styles";
import { Box, Burger } from "UILibrary";
// eslint-disable-next-line @web-monorepo/prefer-dds
import { Button } from "@classdojo/web/nessie";
import useRedirectToBooking from "src/hooks/useRedirectToBooking";

interface HeaderProps {
  navbarOpened?: boolean;
  toggleNavbar?(): void;
}

const LoggedOutHeaderContents: React.FC<HeaderProps> = ({ navbarOpened, toggleNavbar }) => {
  const { classes } = useStyles();
  const burgerTitle = navbarOpened ? "Open navigation" : "Hide navigation";
  const push = useNavigate();
  const { pathname } = useLocation();
  const redirectToBooking = useRedirectToBooking();
  const isLandingPageSave20 = pathname === "/save20";
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Logo sx={{ width: "150px" }} />
      <div sx={{ display: "flex", flexDirection: "row", gap: "dt_l", alignItems: "center" }}>
        {!isMobile && (
          <Box className={classes.headerActions} sx={{ gap: isLandingPageSave20 ? "0" : "inherit" }}>
            {pathname !== "/for-teachers" && (
              <Button data-name="apply-teacher-cta" kind="link" onClick={() => push("/for-teachers")} size="s">
                Apply as a teacher
              </Button>
            )}
            <Button
              data-name="login-cta"
              kind={isLandingPageSave20 ? "link" : "primary"}
              onClick={() => push("/login")}
              size="s"
            >
              Log in
            </Button>
          </Box>
        )}
        {isLandingPageSave20 && (
          <Button data-name="try-campaign-cta" onClick={redirectToBooking} size="s">
            Try a class
          </Button>
        )}
        {isMobile && (
          <Burger
            opened={navbarOpened || false}
            className={classes.mobileHeaderActions}
            size="sm"
            onClick={toggleNavbar}
            title={burgerTitle}
            aria-label={burgerTitle}
          />
        )}
      </div>
    </Box>
  );
};

export default LoggedOutHeaderContents;
