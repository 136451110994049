import { type DAY_KEYS } from "#/src/utils/dayOfWeek";

export type DAY_VALUE = {
  isAvailable: boolean;
  ranges: string[][];
  maxClasses?: string;
};

export const DAYS: Record<DAY_KEYS, DAY_VALUE> = {
  sunday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  monday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  tuesday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  wednesday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  thursday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  friday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
  saturday: {
    ranges: [["8:00 AM", "8:00 PM"]],
    maxClasses: "8",
    isAvailable: false,
  },
};
