import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useOnBack = () => {
  const push = useNavigate();

  return useCallback(() => {
    if (window.history.length <= 1) {
      push("/"); // Fallback to home
    } else {
      push(-1);
    }
  }, [push]);
};
