import { create } from "zustand";
import CancelEnrollmentModal from "old/src/components/CancelEnrollmentModal";
import TutorModal from "src/components/TutorModal";
import FeedbackTermsModal from "#/src/components/FeedbackTermsModal";
import ClassDiscountExclusionModal from "#/src/components/ClassDiscountExclusionModal";
import TutorSelectionChangeDateModal from "#/src/components/TutorSelectionChangeDateModal";
import TutorUnavailableModal from "#/src/components/TutorUnavailableModal";
import { SetSearchDatesPayload } from "#/src/pages/single-class/_components/TutorSelectionStep";

type ModalType =
  | {
      type: "cancel";
      enrollmentId: string;
      fromReschedule?: boolean;
    }
  | {
      type: "subscribe";
    }
  | {
      type: "teacher";
      teacherUID: string;
      options?: {
        onSelectTutor: () => void;
        backButtonText: string;
      };
    }
  | {
      type: "feedbackTerms";
    }
  | {
      type: "classDiscountExclusion";
    }
  | {
      type: "tutorSelectionChangeDate";
      handlePayload: (payload: SetSearchDatesPayload) => void;
    }
  | {
      type: "tutorUnavailable";
    };

type ModalStore = {
  modal?: ModalType;
  hideModal: () => void;
  showCancelModal: (enrollmentId: string, fromReschedule?: boolean) => void;
  showFeedbackTermsModal: () => void;
  showClassDiscountExclusionModal: () => void;
  showTeacherModal: (
    teacherUID: string,
    options?: {
      onSelectTutor: () => void;
      backButtonText: string;
    },
  ) => void;
  showTutorSelectionChangeDateModal: (handlePayload: (payload: SetSearchDatesPayload) => void) => void;
  showTutorUnavailableModal: () => void;
};

export const useModalStore = create<ModalStore>((set) => ({
  hideModal: () => set({ modal: undefined }),
  showCancelModal: (enrollmentId, fromReschedule?: boolean) =>
    set({ modal: { type: "cancel", enrollmentId, fromReschedule } }),
  showTeacherModal: (teacherUID, options) => set({ modal: { type: "teacher", teacherUID, options } }),
  showFeedbackTermsModal: () => set({ modal: { type: "feedbackTerms" } }),
  showClassDiscountExclusionModal: () => set({ modal: { type: "classDiscountExclusion" } }),
  showTutorSelectionChangeDateModal: (handlePayload) =>
    set({ modal: { type: "tutorSelectionChangeDate", handlePayload } }),
  showTutorUnavailableModal: () => set({ modal: { type: "tutorUnavailable" } }),
}));

const Modal = () => {
  const modal = useModalStore((state) => state.modal);

  if (modal == null) return null;

  return (
    <>
      {modal.type === "cancel" && (
        <CancelEnrollmentModal enrollmentId={modal.enrollmentId} fromReschedule={modal.fromReschedule} />
      )}
      {modal.type === "teacher" && <TutorModal tutorId={modal.teacherUID} options={modal.options} />}
      {modal.type === "feedbackTerms" && <FeedbackTermsModal />}
      {modal.type === "classDiscountExclusion" && <ClassDiscountExclusionModal />}
      {modal.type === "tutorSelectionChangeDate" && (
        <TutorSelectionChangeDateModal handlePayload={modal.handlePayload} />
      )}
      {modal.type === "tutorUnavailable" && <TutorUnavailableModal />}
    </>
  );
};

export default Modal;
