import { monolithApiClient } from "#/src/configs/axios.config";
import { useSessionQuery } from "#/src/hooks/useSession";
import { cacheDefaults } from "#/src/queryClient";
import { paths } from "@classdojo/ts-api-types";
import { useQuery } from "@tanstack/react-query";

type Endpoint = paths["/api/marketplace/schedule/slots"]["get"];
type Params = Endpoint["parameters"]["query"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

function getScheduleSlots(params: Params) {
  return monolithApiClient.get<Response>(`/schedule/slots`, { params });
}

export default function useScheduleSlotsQuery(params?: Params) {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["availableSlots", params], () => (params == null ? null : getScheduleSlots(params)), {
    enabled: sessionData !== null && params != null && params.grade != null && params.subject != null,
    refetchOnWindowFocus: false,
    ...cacheDefaults,
  });

  return {
    ...query,
    availableSlots: query.data?.data?.available_slots,
  };
}
