import { useCallback, useState } from "react";
import { useModalStore } from "#/src/components/Modals";
import ModalWrapper from "#/src/components/Modals/ModalWrapper";
import { DDSBodyText, DDSButton } from "@web-monorepo/dds";
import css from "./index.module.css";
import useIsMobile from "#/src/hooks/useIsMobile";
import { UnstyledButton } from "@classdojo/web";
import DaysOfWeekButtonRow from "./DayOfWeekButtonRow";
import TimeRangesButtonRow from "./TimeRangesButtonRow";
import { TimeRange } from "#/src/utils/timeRange";
import { useGlobalStore } from "#/old/src/store";
import { logTutorEvent } from "#/src/utils/log";
const SelectDaysAndTimeRanges = ({
  onModeChange,
  onSave,
}: {
  onModeChange: () => void;
  onSave: (payload: any) => void;
}) => {
  const [state] = useGlobalStore();

  const [selectedDays, setSelectedDays] = useState<string[]>(state.booking?.selectedDays ?? []);
  const [selectedTimeRangeValues, setSelectedTimeRangeValues] = useState<TimeRange["name"][]>(
    state.booking?.selectedTimeRangeValues ?? [],
  );

  const isMobile = useIsMobile();
  const modalVariant = isMobile ? "bottomDrawer" : "centered";

  const onSelectDay = (value: string) => {
    if (selectedDays.includes(value)) {
      setSelectedDays(selectedDays.filter((v) => v !== value));
    } else {
      setSelectedDays([...selectedDays, value]);
    }
  };

  const onSelectTimeRange = (value: TimeRange["name"]) => {
    if (selectedTimeRangeValues.includes(value)) {
      setSelectedTimeRangeValues(selectedTimeRangeValues.filter((v) => v !== value));
    } else {
      setSelectedTimeRangeValues([...selectedTimeRangeValues, value]);
    }
  };

  const hideModal = useModalStore((state) => state.hideModal);

  const onClick = useCallback(() => {
    if (selectedDays.length > 0 && selectedTimeRangeValues.length > 0) {
      onSave({
        selectedDays,
        selectedTimeRangeValues,
      });
    }
    hideModal();
  }, [selectedDays, selectedTimeRangeValues, onSave, hideModal]);

  const submitDisabled = selectedDays.length === 0 || selectedTimeRangeValues.length === 0;

  const childName = state.booking?.first_name_child ?? "your child";

  return (
    <ModalWrapper
      variant={modalVariant}
      title={`When can ${childName} attend tutoring?`}
      maxWidth="440px"
      onClose={() => {
        logTutorEvent({
          eventName:
            "web.marketplace.parent.tutor_trial_checkout.tutor_selection_change_date_modal.select_days_and_times.close.click",
        });
        hideModal();
      }}
    >
      <div className={css.formWrapper}>
        <UnstyledButton
          className={css.modeSwitch}
          data-name="tutor-selection-change-date-modal:select-exact-time"
          onClick={onModeChange}
        >
          <DDSBodyText level={2}>Choose a specific date and time</DDSBodyText>
        </UnstyledButton>

        <DaysOfWeekButtonRow onClick={onSelectDay} currentSelections={selectedDays} />

        <TimeRangesButtonRow onClick={onSelectTimeRange} currentSelections={selectedTimeRangeValues} />

        <DDSButton
          disabled={submitDisabled}
          data-name="tutor-selection-change-date-modal:days-and-times:view-results"
          onClick={() => {
            logTutorEvent({
              eventName:
                "web.marketplace.parent.tutor_trial_checkout.tutor_selection_change_date_modal.select_days_and_times.view_results.click",
              metadata: {
                selectedDays,
                selectedTimeRangeValues,
              },
            });
            onClick();
          }}
        >
          View results
        </DDSButton>
      </div>
    </ModalWrapper>
  );
};

export default SelectDaysAndTimeRanges;
