import { createContext, HTMLAttributes, ReactNode, useMemo } from "react";

type Props<TValue = unknown> = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  children: ReactNode;
  value: TValue;
  onChange: (value: TValue) => void;
};

type ContextType<TValue = unknown> = {
  id: number;
  value: TValue;
  onChange(value: TValue): void;
};

const getId = (() => {
  let currentId = 0;
  return () => currentId++;
})();

export const RadioGroupContext = createContext<ContextType>({
  id: 0,
  onChange: () => {},
  value: null,
});

// eslint-disable-next-line comma-spacing
const RadioGroup = <TValue,>({ children, onChange, value, ...props }: Props<TValue>) => {
  const id = useMemo(getId, []);
  return (
    <RadioGroupContext.Provider value={{ id, value, onChange }}>
      <div {...props}>{children}</div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
